import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  ImageList,
  ImageListItem,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { ordemServicoFotoAction } from '../../_actions'

export const OrdemServicoOrcamentoAnexoEmpresaModal = ({ modal, toggle, props, idOrdemServico }) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const ordemServicoFoto = useSelector(
    (state) => state.ordemServicoFoto.ordemServicoFoto
  )

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    if (idOrdemServico) {
      dispatch(ordemServicoFotoAction.getOrdemServicoFotoPublic({ Id: idOrdemServico }))
    }
  }, [])

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              FOTOS DA ORDEM DE SERVIÇO
            </Typography>
          </Grid>
          <Button
            variant="contained"
            size="small"
            style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
            onClick={() => handleClose()}>
            <CloseIcon />
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            {ordemServicoFoto && undefined !== ordemServicoFoto && ordemServicoFoto.length
              ? ordemServicoFoto.map((n) => {
                return (
                  <Grid item lg={12} md={12} xs={12} align="center">
                    <ImageList cols={4}>
                      <ImageListItem key={n.caminhoAnexo} style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                          style={{ borderRadius: 10, backgroundSize: 'cover', width: 120, height: 120 }}
                          src={n.caminhoAnexo}
                          alt={n.caminhoAnexo}
                          loading="lazy"
                        />
                      </ImageListItem>
                    </ImageList>
                  </Grid>
                )
              })
              :
              <Grid item lg={12} md={12} xs={12} align="center">
                Nenhuma foto encontrada
              </Grid>
            }
          </Grid>
        </Card >
      </DialogContent >
    </Dialog >
  )
}

import {
  Chip,
  Grid,
  IconButton,
  Input,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Page } from 'components'
import { useConfirm } from 'material-ui-confirm'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { authActions, manualAction } from '../../_actions'
import TitleContent from '../TitleContent'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const Manual = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { manual } = props.manual
  const { totalRegistros } = props.manual
  const { isLoading } = props.manual

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('nome');
  const [direction] = useState('asc');
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction
      };

      dispatch(manualAction.getManual(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.manual.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este Manual?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.manual.isLoading,
      confirmationText: props.manual.isLoading ? 'Excluindo manual...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(manualAction.deleteManualById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Manuais">
      {undefined !== manual && manual.length > 0 ? (
        <TitleContent
          rule="manuais.create"
          length={manual.length}
          subTitle={'GERÊNCIA DE MANUAIS'}
          title={'Lista de Manuais'}
          href={'/manual'}
          telaManual={MANUAIS_CATEGORIAS.LISTAGEM_AJUDA}
        />
      ) : (
        <TitleContent
          rule="manuais.create"
          length={0}
          subTitle={'GERÊNCIA DE MANUAIS'}
          title={'Lista de Manuais'}
          telaManual={MANUAIS_CATEGORIAS.LISTAGEM_AJUDA}
          href={'/manual'}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <Typography variant="overline" gutterBottom>
            O administrador do sistema terá acesso a todos manuais
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <Input
            id="term"
            label="term"
            placeholder="Filtro"
            value={term}
            fullWidth
            onChange={handleChange('term')}
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              {authActions.isGestor() ? <TableCell>Tipo(s) de Usuário(s) com Acesso</TableCell> : null}
              <TableCell>Descrição</TableCell>
              <TableCell align="center">{authActions.isGestor() ? 'Ações' : 'Ação'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== manual && manual.length
              ? manual.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row" className={classes.textField}>
                      {n.nome ? n.nome.toUpperCase() : ''}
                    </TableCell>
                    {authActions.isGestor() ?
                      <TableCell component="th" scope="row" className={classes.textField}>
                        <>
                          <Chip
                            label="ADMINISTRADOR"
                            style={{ color: '#000000', margin: 2 }}
                          />
                          {n.tiposUsuariosManuais?.map((t) => (
                            <Chip
                              key={t.tipoUsuario.id}
                              label={t.tipoUsuario.nome}
                              style={{ color: '#000000', margin: 2 }}
                            />
                          ))}
                        </>
                      </TableCell>
                      : null}
                    <TableCell component="th" scope="row" className={classes.textField}>
                      {n.descricao ? n.descricao.toUpperCase() : ''}
                    </TableCell>
                    <TableCell align="center">
                      {n.link ? <IconButton
                        aria-label="Edit"
                        component="a"
                        onClick={() =>
                          window.open(n.link, '_blank')
                        }
                        size="small"
                      >
                        <Tooltip title={'Visualizar manual'}>
                          <Tooltip>
                            <LinkIcon />
                          </Tooltip>
                        </Tooltip>
                      </IconButton> : ''}
                      <AccessControl
                        rule={'manuais.edit'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Edit"
                            component="a"
                            onClick={() =>
                              history.push(`/manual/${n.id}`)
                            }
                            size="small"
                          >
                            <Tooltip title={'Editar'}>
                              <Tooltip>
                                <EditIcon />
                              </Tooltip>
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'manuais.delete'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Delete"
                            onClick={() => handleClick(n.id)}
                            size="small"
                          >
                            <Tooltip title="Excluir">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={authActions.isGestor() ? 4 : 3} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  Array.apply(null, { length: 5 }).map(() => (
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      {authActions.isGestor()
                        &&
                        <>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </>}
                    </TableRow>
                  ))
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        page={page}
        onPageChange={handleChangePage}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
      />
    </Page>
  )
}
Manual.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    manual: state.manual
  }
}
const connectedManualPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Manual))
)
export { connectedManualPage as Manual }


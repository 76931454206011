const initialState = {
  loading: false,
  tipoCombustivel: [],
  open: false,
  id: '',
  nome: ''
}

export function tipoCombustivel(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_TIPO_COMBUSTIVEL':
      return {
        ...state,
        tipoCombustivel: action.tipoCombustivel
      }    
    default:
      return state
  }
}

import React, { useState, useEffect } from 'react'
import {
  Box,
  ButtonBase,
  Dialog,
  Grid,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { history } from '../../utils'
import { empresaOrgaoAction } from '_actions'
import { useDispatch, useSelector } from 'react-redux'
import autonomo from './../../img/autonomo.jpg'
import prestador from './../../img/prestador.jpg'
import fornecedor from './../../img/fornecedor.jpg'

const heightModalL = 270;
const heightModalM = 200;
const heightModalS = 250;

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: heightModalL,
  [theme.breakpoints.down('md')]: {
    width: '100% !important', // Overrides inline-style
    height: heightModalM,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: heightModalS,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    }
  },
}));

const GridCustom = styled(Grid)(({ theme }) => ({
  margin: '0 10px',
  display: 'flex',
  flexDirection: 'column',
  height: heightModalL - 20,
  justifyContent: 'center',
  '&:hover, &.Mui-focusVisible': {
    border: '2px solid currentColor',
    borderRadius: 7
  },
  [theme.breakpoints.down('md')]: {
    width: '100% !important', // Overrides inline-style
    height: heightModalM - 20,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: heightModalS - 20,
  },
}));


const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ModalChooseTipoEmpresa = (props) => {

  const [email, setEmail] = useState()
  const dispatch = useDispatch()
  const [finishedTimeout, setFinishedTimeout] = useState(false);
  const { openModalCadastroTipoEmpresa, isLoading } = useSelector(
    (state) => state.empresaOrgao
  )

  useEffect(() => {
    const id = setTimeout(() => {
      setFinishedTimeout(true);
    }, 5000);

    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (props.currentEmail != undefined) {
      setEmail(props.currentEmail)
    }
  }, [props])

  const handleOpenModalEmpresaCadastro = (type) => {
    history.push(`/empresaCadastro?type=${type}`)
    handleClose();
  }

  const handleClose = () => {
    dispatch(empresaOrgaoAction.openModalCadastroTipoEmpresa(false))
  }

  return (
    <Dialog
      open={openModalCadastroTipoEmpresa}
      onClose={handleClose}
      aria-labelledby="Descrição tipo de ingresso"
      maxWidth="lg"
      fullWidth
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 320, width: '100%' }}>
        <ImageButton
          focusRipple
          key={1}
          onClick={() => handleOpenModalEmpresaCadastro('autonomo')}
          style={{
            width: '33.3%'
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${autonomo})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <GridCustom>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                <b>SOU PROFISSIONAL AUTÔNOMO</b>
              </Typography>
            </GridCustom>
          </Image>
        </ImageButton>

        <ImageButton
          focusRipple
          key={2}
          onClick={() => handleOpenModalEmpresaCadastro('prestador')}
          style={{
            width: '33.4%'
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${prestador})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <GridCustom>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                <b>SOU PRESTADOR DE SERVIÇOS</b>
              </Typography>
            </GridCustom>
          </Image>
        </ImageButton>

        <ImageButton
          focusRipple
          key={3}
          onClick={() => handleOpenModalEmpresaCadastro('fornecedor')}
          style={{
            width: '33.3%'
          }}
        >
          <ImageSrc style={{ backgroundImage: `url(${fornecedor})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <GridCustom>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                <b>SOU FORNECEDOR DE MATERIAIS</b>
              </Typography>
            </GridCustom>
          </Image>
        </ImageButton>
      </Box>
    </Dialog>
  )
}

export default ModalChooseTipoEmpresa

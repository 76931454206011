import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { Page } from 'components'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const PoliticaPrivacidade = (props) => {

  const { classes } = props

  return (
    <Page className={classes.root} title="Política de Privacidade">
      <div>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={12} xs={12} align="center">
            <Typography variant="h5" align="center" style={{ paddingBottom: 20 }}>Política de Privacidade</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">1. A quem essa política de privacidade se aplica?</Typography>
            <Typography variant="body1">Aplica-se a todos os clientes da Rotor: incluindo sistema e aplicativo que de alguma forma tenham os dados pessoais tratados por nós.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">2. Que tipo de informações pessoais coletamos e utilizamos?</Typography>
            <Typography variant="body1">Coletamos dados como nome, sobrenome, e-mail, celular, telefone, CNH e matrícula, porém não se aplica a todas pessoas.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">3. Por que solicitamos seus dados pessoais?</Typography>
            <Typography variant="body1">Os dados pessoais são coletados para termos uma experiência de uso mais assertiva e para controlar corretamente cada usuário dentro das plataformas.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">4. Por quanto tempo armazenamos informações pessoais?</Typography>
            <Typography variant="body1">Por tempo mínimo de 10 anos conforme legislação.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">5. Quais são os direitos do titular de dados?</Typography>
            <Typography variant="body1">O titular dos dados possui o direito de a qualquer momento obter informações sobre como coletamos os dados e como realizamos os tratamentos dos mesmos, assim como solicitar a exclusão dos seus dados pessoais de nossos sistemas.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">6. Uso de cookies</Typography>
            <Typography variant="body1">Utilizamos cookies para melhorar a sua experiência de navegação enquanto usuário de nosso site. Os cookies são usados para acompanhar suas preferências, reconhecendo o seu navegador de internet ou dispositivo.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">7. Compartilhamento de dados</Typography>
            <Typography variant="body1">A Rotor não realiza o compartilhamento de dados pessoais com terceiros que não façam uso diretamente do sistema.</Typography>
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6">8. Como entrar em contato com a empresa solicitando maiores informações?</Typography>
            <Typography variant="body1">Você poderá falar conosco através do formulário abaixo, ou pelo e-mail: contato@forcontrol.com.br</Typography>
          </Grid>
        </Grid>
      </div>
    </Page >
  )
}
PoliticaPrivacidade.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state

}
const connectedPoliticaPrivacidadePage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(PoliticaPrivacidade))
)
export { connectedPoliticaPrivacidadePage as PoliticaPrivacidade }

const initialState = {
  totalRegistros: 0,
  isLoading: false,
  ordemServicoCobranca: [],
  open: false,
  id: 0,
  dataCobranca: '',
  dataPagamento: '',
  idEmpresa: 0,
  caminhoNota: '',
  caminhoComprovantePagamento: '',
  empresa: [],
  ordemServico: [],
  ordensServicosOrcamentosCobrancas: [],
  valor: ''
}

export function ordemServicoCobranca(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_ORDEM_SERVICO_COBRANCA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_ORDEM_SERVICO_COBRANCA_SUCCESS':
      return {
        ...state,
        id: action.ordemServicoCobranca.id,
        isLoading: false,
        dataCobranca: action.ordemServicoCobranca.dataCobranca,
        dataPagamento: action.ordemServicoCobranca.dataPagamento,
        idEmpresa: action.ordemServicoCobranca.idEmpresa,
        caminhoNota: action.ordemServicoCobranca.caminhoNota,
        caminhoComprovantePagamento: action.ordemServicoCobranca.caminhoComprovantePagamento,
        empresa: action.ordemServicoCobranca.empresa,
        ordemServico: action.ordemServicoCobranca.ordemServico,
        ordemServicoCobranca: action.ordemServicoCobranca.ordemServicoCobranca,
        ordensServicosOrcamentosCobrancas: action.ordemServicoCobranca.ordensServicosOrcamentosCobrancas,
        valor: action.ordemServicoCobranca.valor
      }
    case 'CREATE_ORDEM_SERVICO_COBRANCA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_ORDEM_SERVICO_COBRANCA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_ORDEM_SERVICO_COBRANCA_SUCCESS':
      return {
        ...state,
        id: action.ordemServicoCobranca.id,
        isLoading: false,
        dataCobranca: action.ordemServicoCobranca.dataCobranca,
        dataPagamento: action.ordemServicoCobranca.dataPagamento,
        idEmpresa: action.ordemServicoCobranca.idEmpresa,
        caminhoNota: action.ordemServicoCobranca.caminhoNota,
        caminhoComprovantePagamento: action.ordemServicoCobranca.caminhoComprovantePagamento,
        empresa: action.ordemServicoCobranca.empresa,
        ordemServicoCobranca: action.ordemServicoCobranca.ordemServicoCobranca,
        ordemServico: action.ordemServicoCobranca.ordemServico,
        ordensServicosOrcamentosCobrancas: action.ordemServicoCobranca.ordensServicosOrcamentosCobrancas,
        valor: action.ordemServicoCobranca.valor
      }
    case 'EDIT_ORDEM_SERVICO_COBRANCA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_ORDEM_SERVICO_COBRANCA':
      return {
        ...state,
        ordemServicoCobranca: action.ordemServicoCobranca,
        totalRegistros: action.totalRegistros
      }
    case 'ORDEM_SERVICO_COBRANCA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        dataCobranca: action.dataCobranca,
        dataPagamento: action.dataPagamento,
        idEmpresa: action.idEmpresa,
        caminhoNota: action.caminhoNota,
        empresa: action.empresa,
        ordemServicoCobranca: action.ordemServicoCobranca,
        caminhoComprovantePagamento: action.caminhoComprovantePagamento,
        ordemServico: action.ordemServico,
        ordensServicosOrcamentosCobrancas: action.ordensServicosOrcamentosCobrancas,
        valor: action.valor
      }
    case 'ORDEM_SERVICO_COBRANCA_CLEAR':
      return initialState
    case 'ORDEM_SERVICO_COBRANCA_CLEAR_DETAIL':
      return {
        ...state,
        id: 0,
        isLoading: false,
        dataCobranca: '',
        dataPagamento: '',
        idEmpresa: 0,
        caminhoNota: '',
        caminhoComprovantePagamento: '',
        empresa: [],
        ordemServicoCobranca: [],
        ordemServico: [],
        ordensServicosOrcamentosCobrancas: [],
        valor: ''
      }
    case 'ORDEM_SERVICO_COBRANCA_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        ordemServicoCobranca: [...state.ordemServicoCobranca, action.data.ordemServicoCobranca]
      }
    case 'ORDEM_SERVICO_COBRANCA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'ORDEM_SERVICO_COBRANCA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'ORDEM_SERVICO_COBRANCA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

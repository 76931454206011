const initialState = {
  id: 0,
  idNotificacao: '',
  idUsuario: '',
  usuario: null
}

export function notificacaoTeste(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_NOTIFICACAO_TESTE':
      return {
        ...state,
        notificacaoTeste: action.notificacaoTeste,
        totalRegistros: action.totalRegistros
      }
    case 'NOTIFICACAO_TESTE_DETAIL':
      return action.notificacaoTeste
    case 'NOTIFICACAO_TESTE_UPDATED':
      return state
    case 'NOTIFICACAO_TESTE_CLEAR':
      return initialState
    case 'NOTIFICACAO_TESTE_CLEAR_ALL':
      return {
        ...state,
        notificacaoTeste: []
      }
    case 'NOTIFICACAO_TESTE_CLEAR_INFO':
      return {
        ...state,
        id: 0,
        idNotificacao: '',
        idUsuario: '',
        usuario: null
      }
    case 'NOTIFICACAO_TESTE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}

const initialState = {
  isLoading: false,
  usuario: [],
  open: false,
  id: '',
  nome: '',
  idTipoUsuario: '',
}

export function tipoUsuario(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TIPO_USUARIO':
      return {
        ...state,
        tipoUsuario: action.tipoUsuario
      }
    case 'TIPO_USUARIO_CLEAR':
      return initialState
    case 'TIPO_USUARIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TIPO_USUARIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

import { crudService } from '../_services/'

export const tipoVeiculoAction = {
  getTipoVeiculoSelect,
  getTiposVeiculo,
  changeDetailsTipoVeiculo
}

function getTipoVeiculoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposVeiculo/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposVeiculosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTiposVeiculo() {
  return (dispatch) => {
    let apiEndpoint = 'tiposVeiculo'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposVeiculosList(response.data.items))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsTipoVeiculo(tipoVeiculo) {
  return (dispatch) => {
    if (tipoVeiculo) {
      dispatch(onChangeDetailsTipoVeiculo(tipoVeiculo))
    } else {
      clear()
    }
  }
}

export function clear() {
  return {
    type: 'TIPO_VEICULO_CLEAR'
  }
}

export function changeTiposVeiculosList(tipoVeiculo) {
  return {
    type: 'FETCHED_ALL_TIPO_VEICULO',
    tipoVeiculo: tipoVeiculo
  }
}

export function onChangeDetailsTipoVeiculo(tipoVeiculo) {
  return [
    {
      type: 'CHANGE_DETAILS_TIPO_VEICULO',
      id: tipoVeiculo.id,
      nome: tipoVeiculo.nome
    }
  ]
}

import React, { useContext } from 'react';
import { Card, Grid, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ContextEmpenhosInfos } from '../../contexts/ContextEmpenhosInfos';
import theme from '../../theme'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.1),
    textAlign: 'center',
  },
  total: {
    fontWeight: 'bold',
  },
}));

const CardNumberEmpenhos = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const empenhosInfos = useContext(ContextEmpenhosInfos);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{backgroundColor: '#EBEBEB'}}>
          <Typography variant="subtitle2" className={classes.title} gutterBottom>
            RESUMO DE EMPENHOS
          </Typography>
        </Grid>
        <Divider style={{ width: '100%' }} />
        <Grid item lg={4} md={4} xl={4} xs={12} className={classes.item}>
          <Typography variant="body1">
            Empenhos com arquivo: {empenhosInfos?.comArquivo ? empenhosInfos?.comArquivo : 0}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12} className={classes.item}>
          <Typography variant="body1">
            Empenhos com nota fiscal: {empenhosInfos?.comNf ? empenhosInfos?.comNf : 0}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12} className={classes.item}>
          <Typography variant="body1" className={classes.total}>
            <strong>Total de empenhos: {empenhosInfos?.total ? empenhosInfos?.total : 0}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardNumberEmpenhos;

import React from 'react';
import clsx from 'clsx'
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux'
import { Avatar, Typography, CardContent, Grid, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles';
import theme from '../../../../theme'

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        marginLeft: 10,
        marginRight: 10,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    title: {
        fontWeight: 500,
        color: theme.palette.primary.main
    }
}));

const CustomAvatar = styled(Avatar)({
    height: 45,
    width: 45,
    backgroundColor: '#FFC107',
    [theme.breakpoints.down('md')]: {
        height: 35,
        width: 35,
        display: 'flex'
    },
})

const CardInfosValues = (props) => {
    const { className, text, icon, indicator, ...rest } = props
    const classes = useStyles();

    const valor = useSelector((state) => state.dashboard?.dashboard?.[indicator]);

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Grid item styles={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid item>
                            <Typography variant="h5" className={classes.title}>
                               R$ {valor ? valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '0,00'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" gutterBottom>
                                {text}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CustomAvatar>
                            <Icon sx={{ fontSize: 25, color: '#25261C' }}>
                                {icon}
                            </Icon>
                        </CustomAvatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    );
}

CardInfosValues.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.elementType,
    indicator: PropTypes.string.isRequired
}

export default CardInfosValues

import { crudService } from '../_services/'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { ordemServicoAction } from '../_actions'

export const ordemServicoFotoAction = {
  getOrdemServicoFoto,
  getOrdemServicoFotoSelect,
  getOrdemServicoFotoById,
  getOrdemServicoFotoPublic,
  onChangeProps,
  editOrdemServicoFotoInfo,
  createOrdemServicoFoto,
  deleteOrdemServicoFotoById,
  clear
}


function getOrdemServicoFoto(idOrdemServico) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServicoFoto/' + idOrdemServico;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicoFotosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrdemServicoFotoPublic(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServicoFoto/public/fotos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicoFotosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrdemServicoFotoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'ordensServicoFoto/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeOrdemServicoFotosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createOrdemServicoFoto(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServicoFoto'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          dispatch(ordemServicoAction.getOrdemServicoById(response.data.key))
          dispatch(getOrdemServicoFoto(response.data.key))
        } else {
          toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORDEMSERVICO_FOTO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORDEMSERVICO_FOTO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'ORDEMSERVICO_FOTO_CLEAR'
  }
}

function editOrdemServicoFotoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServicoFoto/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          //  history.push('/ordensServicoFotos')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar foto da ordem de serviço! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_ORDEMSERVICO_FOTO_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_ORDEMSERVICO_FOTO_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_FOTO_FAILURE', error }
  }
}

function getOrdemServicoFotoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServicoFotos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editOrdemServicoFotosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteOrdemServicoFotoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoFoto/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteOrdemServicoFotosDetails())
      dispatch(notIsLoading())
      dispatch(ordemServicoAction.getOrdemServicoById(response.data.key))
      dispatch(getOrdemServicoFoto(response.data.key))
    })
  }
}
export function changeOrdemServicoFotosList(ordemServicoFoto, totalRows) {
  return {
    type: 'FETCHED_ALL_ORDEMSERVICO_FOTO',
    ordemServicoFoto: ordemServicoFoto,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'ORDEMSERVICO_FOTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editOrdemServicoFotosDetails(ordemServicoFoto) {
  return {
    type: 'ORDEMSERVICO_FOTO_DETAIL',
    id: ordemServicoFoto.id,
    idTipoFoto: ordemServicoFoto.idTipoFoto,
    caminhoFoto: ordemServicoFoto.caminhoFoto,
    idOrdemServico: ordemServicoFoto.idOrdemServico,
    excluido: ordemServicoFoto.excluido,
    ordemServicoFoto: ordemServicoFoto.ordemServicoFoto
  }
}
export function createUserInfo() {
  return {
    type: 'ORDEMSERVICO_FOTO_CREATED_SUCCESSFULLY'
  }
}
export function deleteOrdemServicoFotosDetails() {
  return {
    type: 'DELETED_ORDEMSERVICO_FOTO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'ORDEMSERVICO_FOTO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ORDEMSERVICO_FOTO_NOTISLOADING'
  }
}

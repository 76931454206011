import { toast } from 'react-toastify'

export default function ErrorsUploadImage(erros) {
  if (erros.acceptType === true) {
    toast.error(`Opss! Tipo de arquivo inválido! 😥`)
  } else if (erros.maxFileSize === true) {
    toast.error(`Opss! A imagem é muito grande! 😥`)
  } else if (erros.maxNumber === true) {
    toast.error(`Opss! Quantidade de imagens excedida! 😥`)
  } else if (erros.resolution === true) {
    toast.error(`Opss! A resolução da imagem é incorreta! 😥`)
  }
}

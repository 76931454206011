import React, { Component } from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { withStyles } from '@mui/styles'
import { history } from 'utils'
import { CircularProgress } from '@mui/material'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    color: '#ffffff'
  },
  backButton: {
    marginTop: theme.spacing(1)
  }
})

class PasswordResetSuccess extends Component {

  handleOpenWeb = () => {
    history.push('/');
  }

  render() {
    const { loading } = this.props.usuario
    const { classes } = this.props

    return (
      <Container component={Paper} maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h2" variant="h6" style={{ marginTop: 20 }}>
            Senha redefinida com sucesso. Escolha a forma de login.
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#000' }}
                disabled={loading}
                className={classes.submit}
                endIcon={loading && <CircularProgress size={24} />}
                onClick={this.handleOpenWeb}
              >
                {loading ? 'Carregando...' : 'Login Administrador (Web)'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    )
  }
}
PasswordResetSuccess.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return state
}
const connectedPasswordResetSuccessPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(PasswordResetSuccess))
)
export { connectedPasswordResetSuccessPage as PasswordResetSuccess }

import React, { useEffect, useState, useContext } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, estadoAction, ordemServicoAction, tipoServicoAction, tipoVeiculoAction } from '../../_actions'
import PropTypes from 'prop-types'
import CircleIcon from '@mui/icons-material/Circle';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import moment from 'moment'
import { useDebounce } from 'use-debounce';
import {
  Chip,
  Grid,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { STATUS_SERVICO, history, getNomeUltimoEventoFormatadoLista, getCor, MANUAIS_CATEGORIAS, isWinner, isRefuseByEmpresa } from '../../utils'
import TitleContent from '../TitleContent'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { OrdemServicoMotivoModal, OrdemServicoModalFiltros } from '../../views/OrdensServico'
import { ContextFiltros } from '../../contexts/ContextFiltros';

const CustomSpan = styled('span')(({ backgroundColor }) => ({
  backgroundColor: backgroundColor || '#000',
  borderRadius: 8,
  padding: 4,
  color: '#fff',
  fontSize: 12,
  marginRight: 4,
  fontWeight: 400
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const OrdemServico = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const { ordemServico } = props.ordemServico
  const { totalRegistros } = props.ordemServico
  const { isLoading } = props.ordemServico
  const [order] = useState('data');
  const [direction] = useState('desc');

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [ready, setReady] = React.useState(false);
  const [idOrdemServico, setIdOrdemServico] = useState(0);

  const [modalMotivo, setModalMotivo] = useState(false);
  const toggleModalMotivo = () => setModalMotivo(!modalMotivo);

  const [modalFiltros, setModalFiltros] = useState(false);
  const toggleModalFiltros = () => setModalFiltros(!modalFiltros);

  const { status, setStatus, handleClearFilters, typeVehicle, typeService, state } = useContext(ContextFiltros);

  const idEmpresa = authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa : 0

  const handleOpenModalMotivo = (idOs) => {
    setIdOrdemServico(idOs)
    toggleModalMotivo();
  }

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleOpenModalFiltros = () => {
    toggleModalFiltros();
  }

  useEffect(() => {

    dispatch(tipoServicoAction.getTiposServicoSelect())
    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(estadoAction.getEstadoSelect())
  }, [])

  const { tipoServico } = useSelector(
    (state) => state.tipoServico
  )

  const { tipoVeiculo } = useSelector(
    (state) => state.tipoVeiculo
  )

  const { estado } = useSelector(
    (state) => state.estado
  )

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: (status.some(s => s.value == '10') || status.length == 0)
          ? '8'
          : Array(status) ? status?.map(s => s.value).join(',') : '8',
        TiposVeiculos: typeVehicle?.map(s => s.value).join(','),
        TiposServicos: typeService?.map(s => s.value).join(','),
        Estados: state?.map(s => s.value).join(',')
      };
      dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, idOrgao])

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.ordemServico.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Ordens de Serviço">
      <AccessControl
        rule={'ordensServico.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== ordemServico && ordemServico.length > 0 ? (
              <TitleContent
                rule={'ordensServico.create'}
                length={ordemServico.length}
                subTitle={'GERÊNCIA DE ORDEM DE SERVIÇO'}
                title={'Lista de Ordem de Serviço'}
                href={'/ordemServico'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORDEM_SERVICO}
              />
            ) : (
              <TitleContent
                rule={'ordensServico.create'}
                length={0}
                subTitle={'GERÊNCIA DE ORDEM DE SERVIÇO'}
                title={'Lista de Ordem de Serviço'}
                href={'/ordemServico'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_ORDEM_SERVICO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <Grid item xl={1} lg={1} md={1} xs={12}>
                <IconButton
                  aria-label="Filtros"
                  component="a"
                  onClick={() =>
                    handleOpenModalFiltros()
                  }
                  size="small"
                >
                  <Tooltip title={'Filtros'}>
                    <Tooltip>
                      <FilterAltRoundedIcon />
                    </Tooltip>
                  </Tooltip>
                </IconButton>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={12} align="left" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="subtitle1">Filtros aplicados:</Typography>
                {(Array.isArray(status) && status.some(s => s.value == '10') || status.length == 0) && typeService?.length == 0 && typeVehicle?.length == 0 && state.length == 0 ? <Chip
                  label={'NENHUM'}
                  style={{ color: '#000000', margin: 4 }}
                />
                  : null
                }
                {status.some(s => s.value != '10') ?
                  <Chip
                    label={status.some(s => s.value == '') ? 'EM LANÇAMENTO' : status.some(s => s.value == '1') ?
                      'PENDENTES' : status.some(s => s.value == '2') ? 'RECEBENDO ORÇAMENTOS' : status.some(s => s.value == '3') ? 'EM EXECUÇÃO' :
                        status.some(s => s.value == '4') ? 'RECUSADAS/EXCLUÍDAS' : status.some(s => s.value == '5') ? 'CONCLUÍDAS' : status.some(s => s.value == '6') ? 'AVALIADAS' :
                          status.some(s => s.value == '7') ? 'VERIFICADAS' : status.some(s => s.value == '8') ? 'TODAS' : ''}
                    style={{ color: '#000000', margin: 4 }}
                  />
                  : null
                }
                {typeVehicle && typeVehicle.length > 0 ? (
                  typeVehicle.map(service => {
                    const nomeTipoVeiculo = tipoVeiculo?.find(t => t.value == service.value)?.text; // Encontrar o nome do tipo do veículo

                    return nomeTipoVeiculo && nomeTipoVeiculo !== '' ? (
                      <Chip
                        key={service}  // Importante adicionar uma chave única
                        label={nomeTipoVeiculo}
                        style={{ color: '#000000', margin: 4 }}
                      />
                    ) : null;
                  })
                ) : null}
                {typeService && typeService.length > 0 ? (
                  typeService.map(service => {
                    const nomeTipoServico = tipoServico?.find(t => t.value == service.value)?.text; // Encontrar o nome do serviço

                    return nomeTipoServico && nomeTipoServico !== '' ? (
                      <Chip
                        key={service}  // Importante adicionar uma chave única
                        label={nomeTipoServico}
                        style={{ color: '#000000', margin: 4 }}
                      />
                    ) : null;
                  })
                ) : null}
                {state && state.length > 0 ? (
                  state.map(service => {
                    const estado1 = estado?.find(t => t.value == service.value)?.text; // Encontrar o uf do estado

                    return estado1 && estado1 !== '' ? (
                      <Chip
                        key={service}  // Importante adicionar uma chave única
                        label={estado1}
                        style={{ color: '#000000', margin: 4 }}
                      />
                    ) : null;
                  })
                ) : null}
                {(typeVehicle && typeVehicle.length > 0)
                  || (typeService && typeService.length > 0)
                  || (state && state.length > 0)
                  || (status.some(s => s.value != '10') && status.some(s => s.value != '8')) ?
                  <IconButton
                    aria-label="Limpar filtros"
                    component="a"
                    onClick={() =>
                      handleClearFilters()
                    }
                    size="small"
                  >
                    <Tooltip title={'Limpar filtros'}>
                      <Tooltip>
                        <FilterAltOffRoundedIcon />
                      </Tooltip>
                    </Tooltip>
                  </IconButton>
                  : null
                }
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Tipo de Serviço</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== ordemServico && ordemServico.length
                    ? ordemServico.map((n) => {
                      return (
                        <TableRow hover key={n.id} className={classes.textField}>
                          <TableCell component="th" scope="row">
                            {n.id ? n.id : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.tipoServico ? n.tipoServico.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              {n.emergencia ? (
                                <Tooltip title="Ordem de serviço emergencial">
                                  <WarningAmberIcon sx={{ color: '#ef5350', fontSize: '1.5em', marginRight: 0.5, verticalAlign: 'middle' }} />
                                </Tooltip>
                              ) : null}
                              <span style={{ verticalAlign: 'middle' }}>{n.titulo ? n.titulo.toUpperCase() : ''}</span>
                            </div>
                            {authActions.isEmpresa() && isWinner(n, idEmpresa) ? <CustomSpan backgroundColor='#00cc66'>Você é a empresa vencedora desta ordem de serviço</CustomSpan> : null}
                            {authActions.isEmpresa() && isRefuseByEmpresa(n, idEmpresa) ? <CustomSpan backgroundColor='#000'>Você decidiu não atender esta ordem de serviço</CustomSpan> : null}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <CircleIcon sx={{ color: getCor(n.ultimoEvento), fontSize: 12, marginRight: 1 }} />
                              <span>
                                {getNomeUltimoEventoFormatadoLista(n.ultimoEvento, n)}
                              </span>
                              {n.ordensServicosEmpenhos && n.ordensServicosEmpenhos.length > 0 &&
                                <Tooltip title='Esta ordem de serviço possui um empenho cadastrado'>
                                  <div style={{ marginLeft: 2, padding: 3, alignItems: 'center', marginTop: 4 }}>
                                    <TurnedInNotIcon style={{ fontSize: '1.5em' }} />
                                  </div>
                                </Tooltip>
                              }
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {authActions.isEmpresa() ?
                              <AccessControl
                                rule={'ordensServico.finalize'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Visualizar"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/ordemServico/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Visualizar'}>
                                      <Tooltip >
                                        <RemoveRedEyeIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              :
                              <AccessControl
                                rule={'ordensServico.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() =>
                                      history.push(`/ordemServico/${n.id}`)
                                    }
                                    size="small"
                                  >
                                    <Tooltip title={'Editar'}>
                                      <Tooltip >
                                        <EditIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                            }
                            {n.ultimoEvento != STATUS_SERVICO.RECUSADO ?
                              <AccessControl
                                rule={'ordensServico.delete'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => handleOpenModalMotivo(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title="Excluir">
                                      <DeleteIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              : null}
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />

      {
        (modalMotivo ?
          <OrdemServicoMotivoModal
            toggle={toggleModalMotivo}
            modal={modalMotivo}
            props={props}
            idOrdemServico={idOrdemServico}
          />
          :
          null
        )
      }

      {
        (modalFiltros ?
          <OrdemServicoModalFiltros
            toggle={toggleModalFiltros}
            modal={modalFiltros}
            props={props}
            term={term}
            page={page}
            limit={limit}
            idOrgao={idOrgao}
          />
          :
          null
        )
      }
    </Page >
  )
}
OrdemServico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedOrdemServicoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServico))
)
export { connectedOrdemServicoPage as OrdemServico }

import { colors } from '@mui/material'

const white = '#FFFFFF'
const black = '#000000'

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.grey[600],
    main: '#000000',
    light: colors.grey[200]
  },
  secondary: {
    contrastText: white,
    dark: '#000000',
    main: '#000000',
    light: '#BF1E21'
  },
  alternative: {
    contrastText: white,
    dark: '#BF1E21',
    main: '#BF1E21',
    light: '#BF1E21'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  yellow: {
    primary: '#F2D647'
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: '#BF1E21',
    white: white
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
}

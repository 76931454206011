import { crudService } from '../_services/'

export const tipoCombustivelAction = {
  getTipoCombustivelSelect,
  getTiposCombustiveis,
  changeDetailsTipoCombustivel
}

function getTipoCombustivelSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposCombustiveis/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposCombustiveisList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTiposCombustiveis() {
  return (dispatch) => {
    let apiEndpoint = 'tiposCombustiveis'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposCombustiveisList(response.data.items))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsTipoCombustivel(tipoCombustivel) {
  return (dispatch) => {
    if (tipoCombustivel) {
      dispatch(onChangeDetailsTipoCombustivel(tipoCombustivel))
    } else {
      clear()
    }
  }
}

export function clear() {
  return {
    type: 'TIPO_COMBUSTIVEL_CLEAR'
  }
}

export function changeTiposCombustiveisList(tipoCombustivel) {
  return {
    type: 'FETCHED_ALL_TIPO_COMBUSTIVEL',
    tipoCombustivel: tipoCombustivel
  }
}

export function onChangeDetailsTipoCombustivel(tipoCombustivel) {
  return [
    {
      type: 'CHANGE_DETAILS_TIPO_COMBUSTIVEL',
      id: tipoCombustivel.id,
      nome: tipoCombustivel.nome
    }
  ]
}

import palette from 'theme/palette'

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 5,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
    callbacks: {
      label: function (tooltipItems, data) {
        var dataset = data.datasets[tooltipItems.datasetIndex]
        if (dataset.yAxisID === 'A') {
          return `${dataset.label}: R$ ${tooltipItems.yLabel.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
        } else {
          return `${dataset.label}: ${tooltipItems.yLabel}`
        }
      }
    }
  },
  layout: { padding: 0 },
  scales: {
    dataset: [
      {
        barThickness: 35, //largura das barras
        maxBarThickness: 37,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        id: 'A',
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
          stepSize: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      },
      {
        id: 'B',
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
          stepSize: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
}

const initialState = {
  totalRegistros: 0,
  isLoading: false,
  empenho: [],
  id: '',
  caminhoArquivo: '',
  caminhoNotaFiscal: '',
  idOrgao: 0,
  dataCadastro: '',
  dataPagamento: '',
  orgao: '',
  idEmpresa: 0,
  empresa: {},
  valor: 0,
  ordensServicosEmpenhos: [],
  excluido: ''
}

export function empenho(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_EMPENHO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_EMPENHO_SUCCESS':
      return {
        ...state,
        id: action.empenho.id,
        isLoading: false,
        caminhoArquivo: action.empenho.caminhoArquivo,
        caminhoNotaFiscal: action.empenho.caminhoNotaFiscal,
        idOrgao: action.empenho.idOrgao,
        dataCadastro: action.empenho.dataCadastro,
        dataPagamento: action.empenho.dataPagamento,
        orgao: action.empenho.orgao,
        idEmpresa: action.empenho.idEmpresa,
        empresa: action.empenho.empresa,
        valor: action.empenho.valor,
        ordensServicosEmpenhos: action.empenho.ordensServicosEmpenhos,
        excluido: action.empenho.excluido
      }
    case 'CREATE_EMPENHO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_EMPENHO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_EMPENHO_SUCCESS':
      return {
        ...state,
        id: action.empenho.id,
        isLoading: false,
        caminhoArquivo: action.empenho.caminhoArquivo,
        caminhoNotaFiscal: action.empenho.caminhoNotaFiscal,
        idOrgao: action.empenho.idOrgao,
        dataCadastro: action.empenho.dataCadastro,
        dataPagamento: action.empenho.dataPagamento,
        orgao: action.empenho.orgao,
        idEmpresa: action.empenho.idEmpresa,
        empresa: action.empenho.empresa,
        valor: action.empenho.valor,
        ordensServicosEmpenhos: action.empenho.ordensServicosEmpenhos,
        excluido: action.empenho.excluido
      }
    case 'EDIT_EMPENHO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_EMPENHO':
      return {
        ...state,
        empenho: action.empenho,
        totalRegistros: action.totalRegistros
      }
    case 'EMPENHO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        caminhoArquivo: action.caminhoArquivo,
        caminhoNotaFiscal: action.caminhoNotaFiscal,
        idOrgao: action.idOrgao,
        dataCadastro: action.dataCadastro,
        dataPagamento: action.dataPagamento,
        orgao: action.orgao,
        idEmpresa: action.idEmpresa,
        empresa: action.empresa,
        valor: action.valor,
        ordensServicosEmpenhos: action.ordensServicosEmpenhos,
        excluido: action.excluido
      }
    case 'EMPENHO_CLEAR':
      return initialState
    case 'EMPENHO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'EMPENHO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'EMPENHO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

import React from 'react'
import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {}
}))

const TitleContentInformation = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h3" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h4" variant="h6">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

TitleContentInformation.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonOnClick: PropTypes.object.isRequired,
  rule: PropTypes.string
}
export default TitleContentInformation

import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const logAction = {
  getLog,
  getTabelas,
  onChangeProps,
  clear,
  clearAll
}

function getLog(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'logs' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLogsList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err)
      })
  }
}

function getTabelas() {
  return (dispatch) => {
    dispatch(isLoading())

    let apiEndpoint = 'logs/tabelas';

    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLogTabelasList(response.data))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

export function changeLogsList(log, totalRows) {
  return {
    type: 'FETCHED_ALL_LOG',
    log: log,
    totalRegistros: totalRows
  }
}

export function changeLogTabelasList(tabelas) {
  return {
    type: 'FETCHED_LOG_TABELAS',
    tabelas: tabelas,
  }
}

export function clear() {
  return {
    type: 'LOG_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'LOG_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'LOG_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editLogsDetails(log) {
  return [
    { type: 'LOG_DETAIL', log },
  ]
}

export function isLoading() {
  return {
    type: 'LOG_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'LOG_NOTISLOADING'
  }
}

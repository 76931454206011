export default () => {
  var today = new Date()
  var curHr = today.getHours()

  if (curHr < 12) {
    return 'Bom dia 🌞'
  } else if (curHr < 18) {
    return 'Boa tarde ✌'
  } else {
    return 'Boa noite 🌜'
  }
}

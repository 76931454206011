import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const marcaAction = {
  getMarca,
  addMarca,
  getMarcaSelect,
  getMarcaById,
  onChangeProps,
  editMarcaInfo,
  editMarcasDetails,
  createMarca,
  deleteMarcaById,
  changeDetailsMarca,
  clear,
  clearAll,
  isLoading,
  notIsLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getMarca(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'marcas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeMarcasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getMarcaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'marcas/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeMarcasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsMarca(marca) {
  return (dispatch) => {
    if (marca) {
      dispatch(onChangeDetailsMarca(marca))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsMarca(marca) {
  return [
    {
      type: 'CHANGE_DETAILS_MARCA',
      id: marca.id,
      nome: marca.nome
    }
  ]
}

function createMarca(payload) {
  return (dispatch) => {
    dispatch(isLoading())  
    let apiEndpoint = 'marcas/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createMarcaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/marcas')
        } else {
          toast.err('Oops! Erro ao cadastrar marca! 😥')
        }
      }).catch((err) => {
        // console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar marca! 😥')
        return err.response;
      })
  }
}

function getMarcaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'marcas/' + id
    crudService.get(apiEndpoint).then((response) => {     
      dispatch(editMarcasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editMarcaInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'marcas/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(updatedMarcaInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(notIsLoading())
            history.push('/marcas')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar marca! 😥')
        //  console.log(err.response);
        return err.response;
      }
      )
  }
}

function deleteMarcaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'marcas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteMarcasDetails())
      dispatch(getMarca(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addMarca(payload) {
  return [{ type: 'ADD_MARCA', marca: payload }, clear()]
}

export function changeMarcasList(marca, totalRows) {
  return {
    type: 'FETCHED_ALL_MARCA',
    marca: marca,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'MARCA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'MARCA_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'MARCA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editMarcasDetails(marca) {
  return [
    { type: 'MARCA_DETAIL', marca },
  ]
}

export function updatedMarcaInfo() {
  return {
    type: 'MARCA_UPDATED'
  }
}

export function createMarcaInfo() {
  return {
    type: 'MARCA_CREATED_SUCCESSFULLY'
  }
}

export function deleteMarcasDetails() {
  return {
    type: 'DELETED_MARCA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'MARCA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'MARCA_NOTISLOADING'
  }
}

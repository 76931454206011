import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography'
import CustomButton from '../../components/CustomButton/CustomButton'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { marcaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils, MANUAIS_CATEGORIAS } from '../../utils'
import SaveIcon from '@mui/icons-material/Save';
import { Page } from 'components'
import { HelpModal, EditorHeader } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const MarcaEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, marca, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {

    if (params.id) {
      dispatch(marcaAction.getMarcaById(params.id))
    } else {
      dispatch(marcaAction.clear())
    }

  }, []);

  useEffect(() => {

  }, [ready, props.marca.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(marcaAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.marca.nome
    }

    if (params.id) {
      dispatch(marcaAction.editMarcaInfo(params.id, payload, true))
    } else {
      dispatch(marcaAction.createMarca(payload))
    }

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Marca'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Marca'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Marcas - Editar Marca'
          : 'Gerência de Marcas - Nova Marca'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formMarca"
                onSubmit={(event) => handleSubmit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Marcas"
                    title={(
                      params.id ?
                        <EditorHeader title='Editar Marca' tabela='Marca' chave={params.id} props={props} />
                        :
                        <InsertText />
                    )}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.marca.nome}
                        variant="outlined"
                        validators={['required', 'maxStringLength: 45']}
                        errorMessages={['Campo obrigatório!', 'Tamanho máximo do campo só pode ser de até 45 caracteres']}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/Marcas')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      dark={true}
                      disabled={props.marca.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.marca.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.marca.isLoading ? 'Atualizando marca...' : 'Atualizar') : (props.marca.isLoading ? 'Salvando marca...' : 'Salvar')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
        {
          (modalHelp ?
            <HelpModal
              toggle={toggleModalHelp}
              modal={modalHelp}
              props={props}
              tela={MANUAIS_CATEGORIAS.CADASTRO_MARCA}
            />
            :
            null
          )
        }
      </Grid>
    </Page>
  )
}

MarcaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedMarcaEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(MarcaEdit))
)
export { connectedMarcaEditPage as MarcaEdit }

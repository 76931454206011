import moment from 'moment'

const initialState = {
  isLoading: false,
  openModalNotificacaoFiltrosItens: false,
  openModalNotificacaoTestes: false,
  openModalNotificacaoUsuarios: false,
  id: 0,
  dataHora: moment(new Date()).format('YYYY-MM-DD HH:mm'),
  titulo: '',
  descricao: '',
  caminhoImagem: '',
  acao: '',
  chave: '',
  excluido: false,
  notificacoesFiltros: [],
  totalNotificacoesUsuarios: 0,
  taxaAbertura: 0
}

export function notificacao(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_NOTIFICACAO':
      return {
        ...state,
        notificacao: action.notificacao,
        totalRegistros: action.totalRegistros
      }
    case 'NOTIFICACAO_DETAIL':
      return action.notificacao
    case 'NOTIFICACAO_UPDATED':
      return state
    case 'NOTIFICACAO_CLEAR':
      return initialState
    case 'NOTIFICACAO_CLEAR_ALL':
      return {
        ...state,
        notificacao: []
      }
    case 'NOTIFICACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OPEN_MODAL_NOTIFICACAO_FILTROS_ITENS':
      return {
        ...state,
        openModalNotificacaoFiltrosItens: action.open
      }
    case 'OPEN_MODAL_NOTIFICACAO_TESTES':
      return {
        ...state,
        openModalNotificacaoTestes: action.open
      }
    case 'OPEN_MODAL_NOTIFICACAO_USUARIOS':
      return {
        ...state,
        openModalNotificacaoUsuarios: action.open
      }
    case 'NOTIFICACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'NOTIFICACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

import React from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@mui/material'
import { options } from './options'
import { useSelector } from 'react-redux'
import theme from './../../../../theme'

const useStyles = makeStyles(() => ({
  root: {},
  bar: {
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 400,
    }
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoOrdensServicoPorMesChartCard = (props) => {

  const { className, ...rest } = props
  const graficoOrdensServicoPorMes = useSelector((state) => state.dashboard ? state.dashboard.dashboard.graficoOrdensServicoPorMes : undefined)
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  const generateData = () => {
    if (graficoOrdensServicoPorMes) {
      const data = {
        labels: graficoOrdensServicoPorMes.options,
        datasets: [
          {
            label: 'Valor total',
            yAxisID: 'A',
            backgroundColor: '#25261C',
            data: graficoOrdensServicoPorMes.series[0]
          },
          {
            label: 'Quantidade',
            yAxisID: 'B',
            backgroundColor: '#FFC107',
            data: graficoOrdensServicoPorMes.series[1]
          }
        ]
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={<Typography variant="subtitle1" align="center">
          Ordens de Serviço Por Mês
        </Typography>}
      />
      <Divider />
      <CardContent style={{ height: graficoOrdensServicoPorMes?.options != undefined && graficoOrdensServicoPorMes?.options.length > 0 ? 400 : 30, alignContent: 'center' }}>
        {graficoOrdensServicoPorMes?.options != undefined && graficoOrdensServicoPorMes?.options.length > 0 ?
          <Bar data={generateData} options={options} className={classes.bar} />
          :
          <Typography align="center">
            Nenhum dado a ser exibido
          </Typography>}
      </CardContent>
      <Divider />
    </Card >
  )
}

GraficoOrdensServicoPorMesChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoOrdensServicoPorMesChartCard

export default (name = '') => {
  if (typeof name !== 'string') {
    return ''; // or whatever default value you want
  }

  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
}

const initialState = {
  anchor: 'left',
  cidade: [],
  open: false,
  id: 0,
  nome: '',
  idEstado: 0,
  ibge: '',
  estado: null
}

export function cidade(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_CIDADE':
      return {
        ...state,
        cidade: action.cidade
      }
    case 'CIDADE_DETAIL':
      return {
        ...state,
        id: action.id,
        nome: action.nome,
        idEstado: action.idEstado,
        ibge: action.ibge,
        estado: action.estado
      }
    case 'CIDADE_UPDATED':
      return state
    case 'CIDADE_CLEAR':
      return initialState
    case 'CIDADE_CLEAR_ALL':
      return {
        ...state,
        cidade: []
      }
    case 'CIDADE_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}

import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ordemServicoAction, tipoServicoAction, tipoVeiculoAction, estadoAction } from '../../_actions'
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { STATUS_SERVICO } from '../../utils'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import { ContextFiltros } from '../../contexts/ContextFiltros';

export const OrdemServicoModalFiltros = ({ modal, toggle, props, page, limit, idOrgao, order, direction }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { status,
    setStatus,
    state,
    setState,
    typeVehicle,
    setTypeVehicle,
    typeService,
    setTypeService,
    handleClearFilters
  } = useContext(ContextFiltros);

  useEffect(() => {
    dispatch(tipoServicoAction.getTiposServicoSelect())
    dispatch(tipoVeiculoAction.getTipoVeiculoSelect())
    dispatch(estadoAction.getEstadoSelect())
  }, [])

  const { tipoVeiculo } = useSelector(
    (state) => state.tipoVeiculo
  )

  const { tipoServico } = useSelector(
    (state) => state.tipoServico
  )

  const { estado } = useSelector(
    (state) => state.estado
  )

  const handleClose = () => {
    toggle();
  }

  const handleApplyFilters = () => {
    let filterModel = {
      Page: page,
      Limit: limit,
      Order: status == STATUS_SERVICO.AVALIADO ? 'dataMudancaSituacao' : order,
      Direction: direction,
      IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
      Status: (status.some(s => s.value == '10') || status.length == 0)
        ? '8'
        : Array(status) ? status?.map(s => s.value).join(',') : '8',
      TiposVeiculos: typeVehicle?.map(s => s.value).join(','),
      TiposServicos: typeService?.map(s => s.value).join(','),
      Estados: state?.map(s => s.value).join(','),
    };

    dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
    toggle();
  };

  const handleChangeTiposServicos = (value) => {
    setTypeService(value);
  };

  const handleChangeEstados = (value) => {
    setState(value);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const handleChangeTiposVeiculos = (value) => {
    setTypeVehicle(value);
  };

  const statusList = [
    { value: '', text: 'EM LANÇAMENTO' },
    { value: '1', text: 'PENDENTES' },
    { value: '2', text: 'RECEBENDO ORÇAMENTOS' },
    { value: '3', text: 'EM EXECUÇÃO' },
    { value: '4', text: 'RECUSADAS' },
    { value: '5', text: 'CONCLUÍDAS' },
    { value: '7', text: 'VERIFICADAS' },
    { value: '6', text: 'AVALIADAS' },
    { value: '8', text: 'TODAS' }
  ];

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Filtros'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleApplyFilters(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Selecionar filtros para aplicar à listagem de Ordens de Serviços"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left" style={{ marginTop: 10 }}>
                  <Autocomplete
                    multiple
                    id="status"
                    value={status?.filter(s => s.value != '10')} 
                    className={classes.textField}
                    margin="normal"
                    options={statusList?.filter(option => option.value != '10' || !status.some(s => s.value == '10'))}
                    getOptionLabel={(option) => option.text}
                    onChange={(event, value) => handleChangeStatus(value)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        variant="outlined"
                        label="Selecione o(s) status"
                      />
                    )}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left" style={{ marginTop: 10 }}>
                  <Autocomplete
                    multiple
                    id="tiposVeiculos"
                    value={typeVehicle}
                    className={classes.textField}
                    margin="normal"
                    options={tipoVeiculo}
                    getOptionLabel={(option) => option.text}
                    onChange={(event, value) => handleChangeTiposVeiculos(value)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        variant="outlined"
                        label="Selecione o(s) tipo(s) de veículo(s)"
                      />
                    )}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left" style={{ marginTop: 10 }}>
                  <Autocomplete
                    multiple
                    id="tiposServicos"
                    className={classes.textField}
                    value={typeService}
                    margin="normal"
                    options={tipoServico}
                    getOptionLabel={(option) => option.text}
                    onChange={(event, value) => handleChangeTiposServicos(value)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className={classes.textField}
                        label="Selecione o(s) tipo(s) de serviço(s)"
                      />
                    )}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} align="left" style={{ marginTop: 10 }}>
                  <Autocomplete
                    multiple
                    id="estados"
                    value={state}
                    options={estado}
                    getOptionLabel={(option) => option.text}
                    onChange={(event, value) => handleChangeEstados(value)}
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        variant="outlined"
                        label="Selecione o(s) estado(s)"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={handleClearFilters}
                >
                  Limpar filtros
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.ordemServico.isLoading}
                  startIcon={<SearchIcon />}
                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                >
                  {props.ordemServico.isLoading ? 'Pesquisando...' : 'Aplicar filtros e pesquisar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}

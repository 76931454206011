import React from 'react'

export const useDidMountEffect = (effect, dependencies) => {
  const mounted = React.useRef(false)
  React.useEffect(() => {
    if (mounted.current) {
      const unmount = effect()
      return () => unmount && unmount()
    } else {
      mounted.current = true
    }
  }, dependencies)

  React.useEffect(() => {
    return () => (mounted.current = false)
  }, [])
}

// hook usado para renderizar o componente somente quando state sofre alguma mudança,
// não dispara na criação do componente como o useEffect() padrão

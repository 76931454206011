import React, { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { deepOrange } from "@mui/material/colors";
import AttachmentIcon from '@mui/icons-material/Attachment';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment'
import { Link } from "react-router-dom";
import { OrdemServicoModalAnexos } from './ordemServicoModalAnexos.component'

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex"
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "end"
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#e6e6e6",
      width: "60%",
      minWidth: 300,
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #e6e6e6",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #e6e6e6",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #e6e6e6",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
      }
    },
    messageYellow: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: "#FFC107",
      width: "60%",
      minWidth: 300,
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #FFC107",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #FFC107",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #FFC107",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
      }
    },
    messageContent: {
      padding: 0,
      margin: 0
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginBottom: 10,
      bottom: "-10px",
      alignContent: 'center',
      alignItems: 'center',
      right: "5px"
    },
    messageUser: {
      [theme.breakpoints.up('md')]: {
        position: "absolute"
      },
      fontSize: ".70em",
      [theme.breakpoints.down('md')]: {
        position: "relative",
        justifyContent: 'start'
      },
      fontWeight: "300",
      marginBottom: 10,
      bottom: "-10px",
      left: "5px",
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    userAndData: {
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      alignItems: 'center',
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    displayName: {
      marginLeft: 20,
      fontSize: 12,
      fontWeight: 600
    },
    displayNameRight: {
      textAlign: 'end',
      justifyContent: 'end',
      marginRight: 20,
      fontSize: 12,
      fontWeight: 600
    },
    anexos: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 2
    },
    labelAnexos: {
      fontSize: 12,
      alignContent: 'center',
      alignItems: 'center',
      marginRight: 1
    }
  })
);

export const MessageLeft = (props) => {
  const message = props.message ? props.message : '';
  const date = props.date ? moment(props.date).format("DD/MM/YYYY HH:mm") : '';
  const displayName = props.displayName ? props.displayName : '';
  const attachment = props.attachment ? props.attachment : ''
  const user = props.user ? props.user : ''

  const classes = useStyles();

  const [modalAnexos, setModalAnexos] = useState(false);
  const toggleAnexos = () => setModalAnexos(!modalAnexos);

  const handleOpenModal = () => {
    toggleAnexos();
  }

  return (
    <>
      <div className={classes.messageRow}>
        <div>
          <div className={classes.displayName}>{displayName}</div>
          <div className={classes.messageBlue}>
            <div>
              <p className={classes.messageContent}>{message}</p>
            </div>
            {attachment && attachment.length > 0 ?  //mudar depois, agora é pra testar só!!!!!!!
              <div className={classes.anexos}>
                <Link to='#' onClick={() => handleOpenModal()} className={classes.labelAnexos}>Visualizar anexos</Link>
                <AttachmentIcon fontSize="small" />
              </div>
              : null}
            <div className={classes.userAndData}>
              <div className={classes.messageTimeStampRight}>{date}</div>
              {user != '' &&
                <div className={classes.messageUser}>
                  <PersonIcon fontSize="small" /> {user}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {
        (modalAnexos ?
          <OrdemServicoModalAnexos toggle={toggleAnexos} modal={modalAnexos} props={props} />
          :
          null
        )
      }
    </>
  );
};

export const MessageRight = (props) => {
  const message = props.message ? props.message : '';
  const date = props.date ? moment(props.date).format("DD/MM/YYYY HH:mm") : '';
  const displayName = props.displayName ? props.displayName : '';
  const attachment = props.attachment ? props.attachment : ''
  const user = props.user ? props.user : ''

  const classes = useStyles();

  const [modalAnexos, setModalAnexos] = useState(false);
  const toggleAnexos = () => setModalAnexos(!modalAnexos);

  const handleOpenModal = () => {
    toggleAnexos();
  }

  return (
    <>
      <div className={classes.displayNameRight}>{displayName}</div>
      <div className={classes.messageRowRight}>
        <div className={classes.messageYellow}>
          <p className={classes.messageContent}>{message}</p>
          {attachment && attachment.length > 0 ?  //mudar depois, agora é pra testar só!!!!!!!
            <div className={classes.anexos}>
              <Link to='#' onClick={() => handleOpenModal()} className={classes.labelAnexos}>Visualizar anexos</Link>
              <AttachmentIcon fontSize="small" />
            </div>
            : null}
          <div className={classes.userAndData}>
            <div className={classes.messageTimeStampRight}>{date}</div>
            {user != '' &&
              <div className={classes.messageUser}>
                <PersonIcon fontSize="small" /> {user}
              </div>
            }
          </div>
        </div>
      </div>
      {
        (modalAnexos ?
          <OrdemServicoModalAnexos toggle={toggleAnexos} modal={modalAnexos} props={props} attachment={attachment} />
          :
          null
        )
      }
    </>
  );
};

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading, notIsLoading } from '../_actions/empenho.actions';
import { crudService } from '_services';
import { stringUtils } from '../utils';

export const ContextEmpenhosInfos = createContext();

export const ContextEmpenhosInfosProvider = ({ children }) => {
  const [empenhosInfos, setEmpenhosInfos] = useState(null);
  const dispatch = useDispatch();

  const { idOrgao } = useSelector((state) => state.usuarioOrgao);

  const getEmpenhosInfos = useCallback(async () => {
    dispatch(isLoading());
    const apiEndpoint = 'empenhos/info' + stringUtils.serialize({ IdOrgao: idOrgao });
    try {
      const response = await crudService.get(apiEndpoint);
      setEmpenhosInfos(response.data);
      dispatch(notIsLoading());
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(notIsLoading());
    }
  }, [dispatch, idOrgao]);

  useEffect(() => {
    getEmpenhosInfos();
  }, [idOrgao, getEmpenhosInfos]);

  return (
    <ContextEmpenhosInfos.Provider value={empenhosInfos}>
      {children}
    </ContextEmpenhosInfos.Provider>
  );
};

const initialState = {
  dashboard: {
    ordensServicoPendentes: 0,
    ordensServicoRecebendoOrcamento: 0,
    ordensServicoAprovadaEmAndamento: 0,
    ordensServicoRecusadas: 0,
    ordensServicoConcluidas: 0,
    ordensServicoPendentesVerificacao: 0,
    ordensServicoDesertas: 0,
    ordensServicoSemSucesso: 0,
    totalOrdens: 0,
    totalUsuarios: 0,
    totalEmpresas: 0,
    empresasPendentes: 0,
    orgaosPendentes: 0,
    graficoOrdensServico: { options: [], series: [] },
    graficoOrdensServicoPorMes: { options: [], series: [] },
    graficoOrdensServicoAprovadasConcluidasPeriodo: { options: [], series: [] },
    horasRecebimentoOrcamentos: '',
    valorTotalEmpenhos: 0,
    valorTotalEmpenhosEmAberto: 0,
    valorTotalEmpenhosPagos: 0
  },
  ordensServico: [],
  totalOrdensServico: 0,
  isLoading: false,
  isLoadingOrdensServicoEmAberto: false,
  isLoadingGraficoOrdensServico: false
}

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_DASHBOARD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_SUCCESS':
      return {
        ...state,
        dashboard: action.dashboard,
        isLoading: false
      }
    case 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_REQUEST':
      return {
        ...state,
        isLoadingGraficoOrdensServico: true
      }
    case 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_SUCCESS':
      return {
        ...state,
        isLoadingGraficoOrdensServico: false,
        ordensServico: action.ordensServico,
        totalOrdensServico: action.totalOrdensServico
      }
    case 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_FAILURE':
      return {
        ...state,
        isLoadingGraficoOrdensServico: false,
        error: action.error
      }
    case 'DASHBOARD_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'DASHBOARD_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'DASHBOARD_CLEAR':
      return initialState
    default:
      return state
  }
}

const initialState = {
  empresaOrgao: [],
  id: '',
  isLoading: false,
  idOrgao: '',
  idEmpresa: '',
  excluido: null,
  empresa: {}
}

export function empresaOrgao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_EMPRESA_ORGAO_SUCCESS':
      return {
        ...state,
        idEmpresa: '',
        empresaOrgao: [
          ...state.empresaOrgao,
          action.empresaOrgao
        ]
      }
    case 'FETCHED_ALL_EMPRESA_ORGAO':
      return {
        ...state,
        empresaOrgao: action.empresaOrgao
      }
    case 'EMPRESA_ORGAO_DETAIL':
      return {
        ...state,
        id: action.id,
        idOrgao: action.idOrgao,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        empresa: action.empresa
      }
    case 'OPEN_MODAL_REQUEST_CADASTRO_TIPO_EMPRESA':
      return {
        ...state,
        openModalCadastroTipoEmpresa: action.open
      }
    case 'EMPRESA_ORGAO_UPDATED':
      return state
    case 'EMPRESA_ORGAO_ADD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EMPRESA_ORGAO_ADD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'EMPRESA_ORGAO_ADD_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'EMPRESA_ORGAO_CLEAR':
      return initialState
    case 'EMPRESA_ORGAO_CLEAR_ALL':
      return {
        ...state,
        empresaOrgao: []
      }
    case 'EMPRESA_ORGAO_CLEAR_SELECTED':
      return {
        ...state,
        id: '',
        isLoading: false,
        idOrgao: '',
        idEmpresa: '',
        excluido: null,
        empresa: {}
      }
    case 'CHANGE_DETAILS_EMPRESA_ORGAO':
      return {
        ...state,
        id: action.id,
        idOrgao: action.idOrgao,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        empresa: action.empresa
      }
    case 'OPEN_MODAL_REQUEST_CADASTRO_EMPRESA_ORGAO':
      return {
        ...state,
        openModalCadastroEmpresaOrgao: action.open
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        empresaOrgao: action.user.usuariosEmpresas
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        empresaOrgao: action.user.usuariosEmpresas
      }
    default:
      return state
  }
}

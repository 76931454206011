import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, makeStyles } from "@mui/styles";
import {
  Avatar,
  Backdrop,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Typography,
  Modal,
  ImageListItem,
  Paper
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { AccessControl } from '../../components/Utils/AccessControl'
import { useConfirm } from 'material-ui-confirm'

const useStyles = makeStyles((theme) =>
  createStyles({
    messagesBody: {
      overflowY: "scroll"
    },
    gridList: {
      flexWrap: "nowrap",
      transform: "translateZ(0)"
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    img: {
      outline: "none"
    }
  })
)

export const OrdemServicoModalAnexos = ({ modal, toggle, props }) => {

  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState("false");

  const classes = useStyles();
  const confirm = useConfirm()
  const { ordemServico, className, ...rest } = props

  const attachment = props.attachment ? props.attachment : ''

  const handleClose = () => {
    toggle();
  }

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setOpenImage(true);
  };

  return (
    <Dialog
      open={modal}
      maxWidth="md"
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle style={{ paddingBottom: 0, marginBottom: 0 }}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={1} style={{ padding: 0, margin: 0 }}>
          <AccessControl
            rule={'ordensServico.list'} //permissão necessária para acessar conteúdo
            yes={() => (
              <Grid item lg={12} md={12} xl={12} xs={12} align="right">
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  endIcon={<CloseIcon />}
                  onClick={() => handleClose()}
                >
                  Fechar
                </Button>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Typography variant="h6" align="center">
              Anexos da Ocorrência
            </Typography>
          </Grid>
          {attachment && attachment.length > 0
            ?
            attachment.map((item) => {
              return (
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <Avatar
                    onClick={(e) => handleImage(item.caminhoArquivo)}
                    sx={{ width: '100%', height: 180 }}
                    key={(item.caminhoArquivo != '' && item.caminhoArquivo != null ? item.caminhoArquivo.substr(item.caminhoArquivo.lastIndexOf('/'), item.caminhoArquivo.length - item.caminhoArquivo.lastIndexOf('/')) : '')}
                    variant="rounded"
                    src={item.caminhoArquivo}
                  />
                </Grid>
              )
            })
            : null
          }
        </Grid>
        <Modal
          className={classes.modal}
          open={openImage}
          onClose={handleCloseImage}
          closeAfterTransition
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={openImage}>
            <img
              src={image}
              alt="asd"
              style={{ maxHeight: "90%", maxWidth: "90%", borderRadius: 5 }}
            />
          </Fade>
        </Modal>
      </DialogContent>
    </Dialog >
  )
}

import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const notificacaoUsuarioAction = {
  getNotificacaoUsuario,
  addNotificacaoUsuario,
  getNotificacaoUsuarioById,
  getNotificacoesByCliente,
  onChangeProps,
  handleOnChangeProps,
  changeNotificacaoUsuariosList,
  /*editNotificacaoUsuarioInfo,*/
  editNotificacaoUsuariosDetails,
  deleteNotificacaoUsuariosDetails,
  createNotificacaoUsuario,
  createNotificacaoUsuarioInfo,
  updatedNotificacaoUsuarioInfo,
  deleteNotificacaoUsuarioById,
  clear,
  clearAll,
  clearInfo,
  isLoading,
  notIsLoading
}

function getNotificacaoUsuario(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'notificacoesUsuarios' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeNotificacaoUsuariosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err)
      })
  }
}

function getNotificacoesByCliente(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesUsuarios/cliente' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeNotificacaoUsuariosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createNotificacaoUsuario(payload) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesUsuarios/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createNotificacaoUsuarioInfo())
      toast.success('Feito! Cadastro realizado com sucesso! 😎')

      let filterModel = {
        Page: 1,
        Limit: 10,
        Term: '',
        IdNotificacao: payload.idNotificacao
      };
      dispatch(getNotificacaoUsuario(filterModel, false))


    })
  }
}

function getNotificacaoUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesUsuarios/' + id
    crudService.get(apiEndpoint).then((response) => {     
      dispatch(editNotificacaoUsuariosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

//function editNotificacaoUsuarioInfo(id, payload, redirect) {
//  return (dispatch) => {
//    let apiEndpoint = 'notificacoesUsuarios/' + id
//    crudService.put(apiEndpoint, payload).then(() => {
//      dispatch(updatedNotificacaoUsuarioInfo())
//      dispatch(clear())
//      if (redirect) {
//        history.push('/notificacoesUsuarios')
//      }
//    })
//  }
//}

function deleteNotificacaoUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesUsuarios/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      var ret = JSON.parse(response.request.responseText);
      dispatch(deleteNotificacaoUsuariosDetails())

      let filterModel = {
        Page: 1,
        Limit: 10,
        Term: '',
        IdNotificacao: ret.key
      };
      dispatch(getNotificacaoUsuario(filterModel, false))

    })
  }
}

function addNotificacaoUsuario(payload) {
  return [{ type: 'ADD_NOTIFICACAO_USUARIO', notificacaoUsuario: payload }, clear()]
}

function changeNotificacaoUsuariosList(notificacaoUsuario, totalRows) {
  return {
    type: 'FETCHED_ALL_NOTIFICACAO_USUARIO',
    notificacaoUsuario: notificacaoUsuario,
    totalRegistros: totalRows
  }
}

function clear() {
  return {
    type: 'NOTIFICACAO_USUARIO_CLEAR'
  }
}

function clearAll() {
  return {
    type: 'NOTIFICACAO_USUARIO_CLEAR_ALL'
  }
}

function clearInfo() {
  return {
    type: 'NOTIFICACAO_USUARIO_CLEAR_INFO'
  }
}

function handleOnChangeProps(props, value) {
  return {
    type: 'NOTIFICACAO_USUARIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

function editNotificacaoUsuariosDetails(notificacaoUsuario) {
  return [
    { type: 'NOTIFICACAO_USUARIO_DETAIL', notificacaoUsuario },
  ]
}

function updatedNotificacaoUsuarioInfo() {
  return {
    type: 'NOTIFICACAO_USUARIO_UPDATED'
  }
}

function createNotificacaoUsuarioInfo() {
  return {
    type: 'NOTIFICACAO_USUARIO_CREATED_SUCCESSFULLY'
  }
}

function deleteNotificacaoUsuariosDetails() {
  return {
    type: 'DELETED_NOTIFICACAO_USUARIO_DETAILS'
  }
}

function isLoading() {
  return {
    type: 'NOTIFICACAO_USUARIO_ISLOADING'
  }
}

function notIsLoading() {
  return {
    type: 'NOTIFICACAO_USUARIO_NOTISLOADING'
  }
}

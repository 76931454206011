const initialState = {
  isLoading: false,
  parametro: [],
  id: '',
  descricao: '',
  chave: '',
  valor: '',
  tipo: '',
  totalRegistros: 0,
};

export function parametro(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_PARAMETRO':
      return {
        ...state,
        parametro: action.parametro,
        totalRegistros: action.totalRegistros
      }
    case 'PARAMETRO_DETAIL':
      return action.parametro
    case 'PARAMETRO_UPDATED':
      return state
    case 'PARAMETRO_CLEAR':
      return initialState
    case 'PARAMETRO_CLEAR_ALL':
      return {
        ...state,
        parametro: []
      }
    case 'PARAMETRO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'PARAMETRO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'PARAMETRO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

import { crudService } from '../_services/'

export const estadoAction = {
  getEstadoSelect,
  changeDetailsEstado
}
function getEstadoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'estados/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeEstadosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsEstado(estado) {
  return (dispatch) => {
    if (estado) {
      dispatch(onChangeDetailsEstado(estado))
    } else {
      clear()
    }
  }
}

export function clear() {
  return {
    type: 'ESTADO_CLEAR'
  }
}

export function onChangeDetailsEstado(estado) {
  return [
    {
      type: 'CHANGE_DETAILS_ESTADO',
      id: estado.id,
      nome: estado.nome,
      uf: estado.uf
    }
  ]
}

export function changeEstadosList(estado) {
  return {
    type: 'FETCHED_ALL_ESTADO',
    estado: estado
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

import React from 'react';
import { View, Text, StyleSheet, Image, Font } from '@react-18-pdf/renderer';

const styles = StyleSheet.create({
  image: {
    padding: 5,
    width: 130
  },
  title: {
    fontSize: 12,
    marginTop: 4,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold'
  },
  subTitle: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
    fontFamily: 'Roboto-Regular'
  },
  info: {
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular'
  },
  details: {
    fontSize: 8,
    textAlign: 'left',
    fontFamily: 'Roboto-Regular'
  },
  header: {
    position: 'absolute',
    top: 20,
    left: 20,
    right: 25
  },
});

const font = require('./fonts/Roboto-Regular.ttf')
const fontBold = require('./fonts/Roboto-Bold.ttf')

Font.register({ family: 'Roboto-Regular', src: font, fontStyle: 'normal', fontWeight: 'normal' });
Font.register({ family: 'Roboto-Bold', src: fontBold, fontStyle: 'normal', fontWeight: 'bold' });

const Header = (props) => {

  return (
    <View style={styles.header}>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ alignItems: "flex-start", width: "10%", textAlign: "center" }} >
          <Image style={styles.image} src={require('../img/rotorSemFundo.png')} />
        </View>
        <View style={{ alignItems: "center", width: "90%", textAlign: "center" }} >
          {(props.info != null ? <Text style={styles.info}>{props.info}</Text> : null)}
          <Text style={styles.title}>{props.title}</Text>
        </View>
      </View>
    </View>
  )

};

export { Header };

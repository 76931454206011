import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { placaMask } from '../../utils'
import {
  relatorioAction
} from '../../_actions'
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import moment from 'moment'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const CustomPage = styled(Page)(() => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioServicoRealizadoPrint = (props) => {

  const dispatch = useDispatch()
  const [ready, setReady] = React.useState(false);
  const { relatorio } = props.relatorio
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {

  }, [props.relatorio]);

  const filterModel = {
    IdTipoServico: queryParams.get('IdTipoServico'),
    RazaoSocial: queryParams.get('RazaoSocial'),
    Cnpj: queryParams.get('Cnpj'),
    PlacaVeiculo: queryParams.get('PlacaVeiculo'),
    Page: queryParams.get('Page'),
    IdOrgao: queryParams.get('IdOrgao'),
    Limit: queryParams.get('Limit')
  };

  useEffect(() => {
    const modifiedFilterModel = {
      ...filterModel,
      Limit: 9999999
    };

    dispatch(relatorioAction.getRelatorioServicosRealizados(modifiedFilterModel, true));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document onRender={onRendered} width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header
                title='Relatório Serviços Realizados'
                info={<Text>ROTOR - FABRICAÇÃO, MANUTENÇÃO, REPARAÇÃO E FORNECIMENTO LTDA - 40.691.238/0001-25</Text>}
              />
              <View style={[styles.row, { marginTop: 60 }]} key={0} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>DATA</Text>
                </View>
                <View style={[styles.header, { width: 250 }]}>
                  <Text>SERVIÇO REALIZADO</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>TIPO DE SERVIÇO</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>RAZÃO SOCIAL</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>PLACA VEÍCULO</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>VALOR</Text>
                </View>
              </View>
              {
                relatorio.length > 0 ? relatorio.map(n => {
                  var retorno = (
                    <View style={[styles.row]} key={n.id} wrap={false}>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.dataOrdemServico ? moment(n.dataOrdemServico).format('DD/MM/yyyy') : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 250 }]}>
                        <Text># {n.idOrdemServico} {n.tituloOrdemServico ? n.tituloOrdemServico.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.tipoServico ? n.tipoServico.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 200 }]}>
                        <Text>{n.nomeEmpresa ? n.nomeEmpresa.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.placaVeiculo ? placaMask(n.placaVeiculo) : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text> {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioServicoRealizadoPrintPage = withRouter(connect(mapStateToProps, null, null)(RelatorioServicoRealizadoPrint));
export { connectedRelatorioServicoRealizadoPrintPage as RelatorioServicoRealizadoPrint };

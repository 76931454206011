const initialState = {
  loading: false,
  tipoVeiculo: [],
  open: false,
  id: '',
  nome: ''
}

export function tipoVeiculo(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_TIPO_VEICULO':
      return {
        ...state,
        tipoVeiculo: action.tipoVeiculo
      }    
    default:
      return state
  }
}

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  authActions,
  ordemServicoOrcamentoAction
} from '../../_actions'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

export const OrdemServicoDeclinarOrcamentoMotivoModal = ({ modal, toggle, props, ordemServico }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const [motivo, setMotivo] = useState('');

  const handleClose = () => {
    toggle();
  }

  const { user } = useSelector(
    (state) => state.authentication
  )

  const handleSubmit = () => {
    let payload = {
      observacoes: motivo,
      declinio: true,
      data: moment().format('YYYY-MM-DD'), //pega a data de hoje
      idOrdemServico: ordemServico.id,
      idEmpresa: authActions.isEmpresa() && user && user.usuariosEmpresas && user.usuariosEmpresas[0].idEmpresa,
    }

    if (motivo && motivo != '') {
      dispatch(ordemServicoOrcamentoAction.createOrdemServicoOrcamentoDeclinar(payload));
      toggle();
    } else {
      toast.error('Informe uma justificativa');
    }
  };

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Declinar Orçamento'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Não participar da disputa de orçamentos para Ordem de Serviço"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="motivo"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    label="Informe a justificativa"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={(e) => setMotivo(e.target.value)}
                    value={motivo}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.ordemServico.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                >
                  {props.ordemServico.isLoading ? 'Declinando ordem de serviço...' : 'Declinar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}

const initialState = {
  isLoading: false,
  totalRegistros: 0,
  log: [],
  id: 0,
  tabela: '',
  chave: '',
  tipo: '',
  usuario: '',
  dataHora: '',
  campo: '',
  valorAntigo: '',
  valorNovo: '',
  tabelas: [],
  page: 0,
  limit: 10,
  order: 'dataHora',
  direction: 'desc',
  term: ''
}

export function log(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_LOG':
      return {
        ...state,
        log: action.log,
        totalRegistros: action.totalRegistros
      }
    case 'FETCHED_LOG_TABELAS':
      return {
        ...state,
        tabelas: action.tabelas,
      }
    case 'LOG_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.log.id,
        tabela: action.log.tabela,
        chave: action.log.chave,
        tipo: action.log.tipo,
        usuario: action.log.usuario,
        dataHora: action.log.dataHora,
        campo: action.log.campo,
        valorAntigo: action.log.valorAntigo,
        valorNovo: action.log.valorNovo
      }
    case 'LOG_UPDATED':
      return state
    case 'LOG_CLEAR':
      return {
        ...initialState,
        tabelas: [],
        page: state.page,
        limit: state.limit,
        order: state.order,
        direction: state.direction,
        term: state.term,
        table: state.table,
        key: state.key
      }
    case 'LOG_CLEAR_ALL':
      return {
        ...state,
        log: []
      }
    case 'LOG_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'LOG_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'LOG_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { history } from '../../utils'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';
import { uploadFile } from '_services'
import {
  Backdrop,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  Input,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import CustomButton from '../../components/CustomButton/CustomButton'
import ChecklistIcon from '@mui/icons-material/Checklist';
import { ordemServicoCobrancaAction, ordemServicoOrcamentoAction } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Page as PageForm } from 'components'
import PropTypes from 'prop-types'
import { withRouter, } from 'react-router-dom'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withStyles } from '@mui/styles'
import { OrdemServicoCobrancaEditConfirmationModal } from '..';
import OrdemServicoSummaryCard from '../OrdensServico/ordemServicoSummaryCard.component'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const OrdemServicoCobrancaEdit = (props) => {

  const { isLoading } = props.ordemServico
  const { ordemServicoOrcamento } = props.ordemServicoOrcamento

  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [key, setKey] = useState(1);

  const { classes, className, ...rest } = props

  const location = useLocation();
  const idOrdemServico = location.state;

  const dispatch = useDispatch()
  const [valorOrcamento, setValorOrcamento] = useState(0);
  const [file, setFile] = useState(null)
  const [open, setOpen] = useState(false)

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if ((props.authentication.user.usuariosEmpresas[0]?.idEmpresa || props.ordemServico.idEmpresa) && idOrdemServico) {
      dispatch(ordemServicoOrcamentoAction.getOrdemServicoOrcamentoByEmpresa(
        {
          IdEmpresa: props.authentication.user.usuariosEmpresas[0]?.idEmpresa ? props.authentication.user.usuariosEmpresas[0].idEmpresa : props.ordemServico.idEmpresa,
          IdOrdemServico: idOrdemServico
        }
      ))
    }
  }, [])

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const upload = () => {
    setOpen(true)
    uploadFile(file)
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(ordemServicoCobrancaAction.onChangeProps('caminhoNota', event2))

          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (file && file != '' && file.name) {
      setOpen(true)
      upload()
    }
  }, [file])

  const handleOpenModalConfirmation = () => {
    if (props.ordemServicoCobranca.caminhoNota != '' && props.ordemServicoCobranca.caminhoNota != null && props.ordemServicoCobranca.caminhoNota != undefined) {
      toggle();
    } else {
      toast.error('É necessário anexar uma nota fiscal para prosseguir');
    }
  };

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Visualizar Cobrança'}</b>
      </Typography>
    )
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Cobrança'}</b>
      </Typography>
    )
  }

  return (
    <PageForm
      className={classes.root}
      title="Gerência de Cobrança - Visualizar Cobrança"
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        {(idOrdemServico) &&
          <OrdemServicoSummaryCard idOrdemServico={idOrdemServico} />
        }
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrdemServicoCobranca"
              >
                <CardHeader
                  subheader="Cadastro de Cobrança"
                  title={<SegHeader />}
                />
                <Divider />
                {!params.id ?
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell style={{ minWidth: '200px' }}>Valor</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {undefined !== ordemServicoOrcamento && ordemServicoOrcamento.length
                            ? ordemServicoOrcamento.map((n) => {
                              return (
                                <TableRow hover key={n.id}>
                                  <TableCell component="th" scope="row">
                                    {n.data ? moment(n.data).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n.prazo ? moment(n.prazo).format("DD/MM/YYYY") : ''}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <TextValidator
                                      className={classes.textField}
                                      fullWidth
                                      id="valor"
                                      label="Valor do Orçamento"
                                      margin="normal"
                                      type="number"
                                      InputProps={{
                                        startAdornment: <Typography position="left">R$&nbsp;</Typography>
                                      }}
                                      inputProps={{
                                        min: 0,
                                        step: '0.1'
                                      }}
                                      onChange={(e) => setValorOrcamento(e.target.value)}
                                      value={valorOrcamento ? valorOrcamento.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0 || 0}
                                      variant="outlined"
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {n ? n.statusOrcamento == 1 ? 'APROVADO' : 'REPROVADO' : ''}
                                  </TableCell>
                                </TableRow >
                              )
                            })
                            :
                            (
                              !isLoading ?
                                <TableRow>
                                  <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                                </TableRow>
                                :
                                Array.apply(null, { length: 5 }).map((e, i) => (
                                  <TableRow hover>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Skeleton animation="wave" />
                                    </TableCell>
                                  </TableRow>
                                ))
                            )
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 15 }}>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <CircularProgress color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textPrimary">
                                Enviando nota...
                              </Typography>
                            </Grid>
                          </Grid>
                        </Backdrop>
                        <label>Anexar nota *</label>
                        <Input
                          id="caminhoNota"
                          style={{ marginTop: 3 }}
                          label="Anexar nota *"
                          onChange={e => handleChangeFile(e)}
                          type="file"
                          variant="outlined"
                          margin="normal"
                          placeHolder="Escolher arquivo *"
                          formControl
                          key={key}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="valorCobranca"
                          label="Valor total da Cobrança"
                          margin="normal"
                          InputProps={{
                            startAdornment: <Typography position="left">R$&nbsp;</Typography>
                          }}
                          value={valorOrcamento ? valorOrcamento.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0 || 0}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  :
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="dataCobranca"
                          label="Data da Cobrança"
                          margin="normal"
                          value={moment(props.ordemServicoCobranca.dataCobranca).format("DD/MM/YYYY") || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="dataPagamento"
                          label="Data do Pagamento"
                          margin="normal"
                          value={props.ordemServicoCobranca.dataPagamento ? moment(props.ordemServicoCobranca.dataPagamento).format("DD/MM/YYYY HH:mm") : ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="empresa"
                          label="Empresa"
                          margin="normal"
                          value={props.ordemServico.empresa?.razaoSocial?.toUpperCase()}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6} xl={6} xs={12} >
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="obra"
                            label="OrdemServico"
                            margin="normal"
                            value={
                              props.ordemServico?.obra?.titulo?.toUpperCase() || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            multiline
                            readOnly
                            minRows={2}
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="descritivo"
                            label="Descritivo"
                            margin="normal"
                            value={
                              props.ordemServico?.obra?.descritivo?.toUpperCase() || ''
                            }
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="valor"
                            label="Valor"
                            margin="normal"
                            value={`R$ ${props.ordemServico.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            label="Status"
                            margin="normal"
                            readOnly
                            variant="outlined"
                            value={props.ordemServicoCobranca.dataPagamento != null ? 'PAGO' : 'EM ABERTO'}
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={5} md={5} xl={5} xs={12} align="center">
                        <FormLabel>
                          <Typography variant="subtitle1" align="center">Nota Fiscal</Typography>
                        </FormLabel>
                        {props.ordemServicoCobranca.caminhoNota && (props.ordemServicoCobranca.caminhoNota.includes('.jpg') || props.ordemServicoCobranca.caminhoNota.includes('.jpeg') || props.ordemServico.caminhoNota.includes('.png')) ?
                          <img
                            style={{ width: 350, height: 450, objectFit: 'contain', padding: 3, justifycontent: 'right', borderRadius: 7 }}
                            src={props.ordemServicoCobranca.caminhoNota}
                            alt="Image"
                          />
                          :
                          props.ordemServicoCobranca.caminhoNota && props.ordemServicoCobranca.caminhoNota.includes('.pdf') ?
                            <iframe
                              src={`https://docs.google.com/viewer?url=${props.ordemServicoCobranca.caminhoNota}&embedded=true`}
                              style={{ width: 450, height: 450, objectFit: 'contain', padding: 3, justifycontent: 'right', borderRadius: 7 }}
                              frameborder="0"
                              scrolling="no"
                            >
                            </iframe>
                            : null
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                }
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.push('/ordensServicosCobrancas')}
                    >
                      Cancelar
                    </CustomButton>
                    {!params.id &&
                      <CustomButton
                        variant="contained"
                        dark={true}
                        type="submit"
                        onClick={() => handleOpenModalConfirmation()}
                        disabled={props.ordemServicoCobranca.isLoading}
                        startIcon={<ChecklistIcon />}
                      >
                        Revisar e enviar cobrança
                      </CustomButton>
                    }
                  </Grid>
                </CardActions>
              </ValidatorForm>
              {
                (modal ?
                  <OrdemServicoCobrancaEditConfirmationModal toggle={toggle} modal={modal} props={props} idOrdemServico={idOrdemServico} idOrgao={idOrgao} valorOrcamento={valorOrcamento} ordemServicoOrcamento={ordemServicoOrcamento} ordemServicoCobranca={props.ordemServicoCobranca} />
                  :
                  null
                )
              }
            </Card >
          </Grid >
        </Grid >
      </Grid >
    </PageForm >
  )
};

OrdemServicoCobrancaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrdemServicoCobrancaEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrdemServicoCobrancaEdit))
)
export { connectedOrdemServicoCobrancaEditPage as OrdemServicoCobrancaEdit }

export default OrdemServicoCobrancaEdit;

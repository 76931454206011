import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const subOrgaoAction = {
  getSubOrgao,
  getSubOrgaoSelect,
  getSubOrgaoById,
  onChangeProps,
  editSubOrgaoInfo,
  getSubOrgaoByIdOrgao,
  editAprovaCadastroSubOrgao,
  editRecusaCadastroSubOrgao,
  changeDetailsSubOrgao,
  createSubOrgao,
  deleteSubOrgaoById,
  clear,
  isLoading,
  notIsLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getSubOrgao(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'subOrgaos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeSubOrgaosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getSubOrgaoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'subOrgaos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeSubOrgaosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createSubOrgao(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'subOrgaos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/subOrgaos')
        } else {
          //     toast.err('Oops! Erro ao cadastrar órgão! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //    toast.err('Oops! Erro ao cadastrar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_SUB_ORGAO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_SUB_ORGAO_FAILURE', error }
  }
}

function failure(error) {
  return { type: 'EDIT_SUB_ORGAO_FAILURE', error }
}

export function clear() {
  return {
    type: 'SUB_ORGAO_CLEAR'
  }
}

function getSubOrgaoByIdOrgao(idOrgao) {
  //console.log("entrei aqui", idEstado)
  return (dispatch) => {
    let apiEndpoint = 'subOrgaos/select/orgao/' + idOrgao
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeSubOrgaosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editAprovaCadastroSubOrgao(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'subOrgaos/aprova/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/subOrgaos')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_SUB_ORGAO_FAILURE', error }
  }
}

function editRecusaCadastroSubOrgao(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'subOrgaos/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/subOrgaos')
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }
}

function editSubOrgaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'subOrgaos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/subOrgaos')
        } else {
          //  toast.error('Oops! Erro ao alterar órgão! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.error('Oops! Erro ao alterar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_SUB_ORGAO_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_SUB_ORGAO_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_SUB_ORGAO_FAILURE', error }
  }
}

function getSubOrgaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'subOrgaos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editSubOrgaosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function changeDetailsSubOrgao(subOrgao) {
  return (dispatch) => {
    if (subOrgao) {
      dispatch(onChangeDetailsSubOrgao(subOrgao))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsSubOrgao(subOrgao) {
  return [
    {
      type: 'CHANGE_DETAILS_SUB_ORGAO',
      id: subOrgao.id,
      nome: subOrgao.nome
    }
  ]
}

function deleteSubOrgaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'subOrgaos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteSubOrgaosDetails())
      history.push('/subOrgaos')
      dispatch(getSubOrgao(filterModel, true))
      dispatch(notIsLoading())
    })
  }
}
export function changeSubOrgaosList(subOrgao, totalRows) {
  return {
    type: 'FETCHED_ALL_SUB_ORGAO',
    subOrgao: subOrgao,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'SUB_ORGAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editSubOrgaosDetails(subOrgao) {
  return {
    type: 'SUB_ORGAO_DETAIL',
    id: subOrgao.id,
    isLoading: subOrgao.isLoading,
    nome: subOrgao.nome,
    orgao: subOrgao.orgao,
    idOrgao: subOrgao.idOrgao,
    excluido: subOrgao.excluido
  }
}
export function createUserInfo() {
  return {
    type: 'SUB_ORGAO_CREATED_SUCCESSFULLY'
  }
}
export function deleteSubOrgaosDetails() {
  return {
    type: 'DELETED_SUB_ORGAO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'SUB_ORGAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'SUB_ORGAO_NOTISLOADING'
  }
}



import React, { useState, useEffect } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { usuarioAction } from '_actions'
import { useDispatch, useSelector } from 'react-redux'

const ModalRequestPasswordReset = (props) => {

  const [email, setEmail] = useState()
  const dispatch = useDispatch()
  const [finishedTimeout, setFinishedTimeout] = useState(false);
  const { openModalRequestPasswordReset, isLoading } = useSelector(
    (state) => state.usuario
  )

  useEffect(() => {
    const id = setTimeout(() => {
      setFinishedTimeout(true);
    }, 5000);

    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (props.currentEmail != undefined) {
      setEmail(props.currentEmail)
    }
  }, [props])

  const handleChange = (event) => {
    setEmail(event.target.value.toLowerCase())
  }
  const handleClose = () => {
    dispatch(usuarioAction.openModalRequestPasswordReset(false))
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(usuarioAction.requestPasswordReset(email))
    { finishedTimeout && setEmail('') }
  }
  return (
    <Dialog
      open={openModalRequestPasswordReset}
      onClose={handleClose}
      aria-labelledby="Descrição tipo de ingresso"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0, marginBottom: 0 }}>
        Recuperação de senha perdida
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Typography
              gutterBottom
              style={{ paddingTop: 0, paddingBottom: 4 }}
              align="justify"
              variant="subtitle1"
              color="textPrimary"
            >
              Informe o endereço de e-mail utilizado no cadastro para recuperar
              a senha.
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            required
            id="email"
            label="Endereço de e-mail"
            inputProps={{
              minLength: '3'
            }}
            placeholder="Endereço de e-mail"
            type="email"
            value={email}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Grid
            style={{ padding: 5 }}
            spacing={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined" style={{ color: '#000', borderColor: '#000' }}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#000' }}
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                {isLoading
                  ? 'Enviando email...'
                  : 'Enviar e-mail de recuperação de senha'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalRequestPasswordReset

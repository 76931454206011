import React from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@mui/material'
import { options } from './options'
import { useSelector } from 'react-redux'
import theme from './../../../../theme'

const useStyles = makeStyles(() => ({
  root: {},
  bar: {
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 400,
    }
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoOrdensServicoAprovadasEncerradasPeriodoChartCard = (props) => {

  const { className, ...rest } = props
  const graficoOrdensServicoAprovadasConcluidasPeriodo = useSelector((state) => state.dashboard ? state.dashboard.dashboard.graficoOrdensServicoAprovadasConcluidasPeriodo : undefined)
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  const generateData = () => {
    if (graficoOrdensServicoAprovadasConcluidasPeriodo) {
      const data = {
        labels: graficoOrdensServicoAprovadasConcluidasPeriodo.options,
        datasets: [
          {
            label: 'Aprovadas',
            backgroundColor: '#25261C',
            data: graficoOrdensServicoAprovadasConcluidasPeriodo.series[0],
          },
          {
            label: 'Concluídas',
            backgroundColor: '#FFC107',
            data: graficoOrdensServicoAprovadasConcluidasPeriodo.series[1],
          },
        ],
      };
      return data;
    } else {
      return [];
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={<Typography variant="subtitle1" align="center">
          Ordens de Serviço Aprovadas/Concluídas por período
        </Typography>}
      />
      <Divider />
      <CardContent style={{ height: graficoOrdensServicoAprovadasConcluidasPeriodo?.options != undefined && graficoOrdensServicoAprovadasConcluidasPeriodo?.options.length > 0 ? 400 : 30, alignContent: 'center' }}>
        {graficoOrdensServicoAprovadasConcluidasPeriodo?.options != undefined && graficoOrdensServicoAprovadasConcluidasPeriodo?.options.length > 0 ?
          <Bar data={generateData} options={options} className={classes.bar} />
          :
          <Typography align="center">
            Nenhum dado a ser exibido
          </Typography>}
      </CardContent>
      <Divider />
    </Card >
  )
}

GraficoOrdensServicoAprovadasEncerradasPeriodoChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoOrdensServicoAprovadasEncerradasPeriodoChartCard

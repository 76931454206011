import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export function formatRelativeTime(date) {
    const now = moment();
    const duration = moment.duration(now.diff(moment(date)));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let relativeTime = 'há ';

    if (days > 0 && days != 1) {
        relativeTime += `${days} dias`;
    }
    if (days > 0 && days == 1) {
        relativeTime += `${days} dia`;
    }
    if (hours > 0 && hours != 1) {
        relativeTime += `${days > 0 ? ' e ' : ''}${hours} horas`;
    }
    if (hours > 0 && hours == 1) {
        relativeTime += `${days > 0 ? ' e ' : ''}${hours} hora`;
    }
    if (minutes > 0 && days === 0 && hours === 0 && minutes != 1) {
        relativeTime += `${minutes} minutos`;
    }
    if (minutes > 0 && days === 0 && hours === 0 && minutes == 1) {
        relativeTime += `${minutes} minuto`;
    }
    if (seconds > 0 && days === 0 && hours === 0 && minutes === 0 && seconds != 1) {
        relativeTime += `${seconds} segundos`;
    }
    if (seconds > 0 && days === 0 && hours === 0 && minutes === 0 && seconds == 1) {
        relativeTime += `${seconds} segundo`;
    }

    return relativeTime;
};
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Avatar,
  FormControlLabel,
  Paper,
  Grid,
  Button,
  Box,
  Typography,
  CssBaseline,
  Checkbox,
  Link
} from '@mui/material'
import { connect } from 'react-redux'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import packageInfo from '../../../package.json';
import { authActions, usuarioAction, empresaOrgaoAction } from '../../_actions'
import { history, stringUtils } from '../../utils'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import ModalRequestPasswordReset from './ModalRequestPasswordReset'
import background from "../../img/login.png";
import logo from "../../img/rotorSemFundo.png";
import ModalChooseEmpresaOrgao from './ModalChooseEmpresaOrgao';
import ModalChooseTipoEmpresa from './ModalChooseTipoEmpresa';

const styles = (theme) => ({
  root: {
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  image: {
    backgroundImage: `url(/images/logo_quiero_cafe.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.secondary.dark
        : theme.palette.secondary.light,
    backgroundSize: '40rem',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  backButtom: {}
})

function Copyright() {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Desenvolvido por © '}
        <Link color="inherit" href="https://fortalezatec.com.br/">
          FortalezaTec
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Versão do sistema: {packageInfo.version}
      </Typography>
    </>
  )
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      senha: '',
      showPassword: false,
      submitted: false
    }
  }

  componentDidMount() {
    if (localStorage.getItem('auth')) {
      history.push('/dashboard')
    }
  }
  handleChange = (prop) => (event) => {
    if (prop === 'email') {
      this.setState({ [prop]: event.target.value.toLowerCase() })
    } else {
      this.setState({ [prop]: event.target.value })
    }
  }

  handleChangeBox = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { email, senha } = this.state
    const { dispatch } = this.props

    if (email && senha) {
      dispatch(authActions.login(email, senha))
    }
  }



  handleClickopenModalRequestPasswordReset = () => {
    const { dispatch } = this.props
    dispatch(usuarioAction.openModalRequestPasswordReset(true))
  }

  handleClickopenModalCadastroEmpresaOrgao = () => {
    const { dispatch } = this.props
    dispatch(empresaOrgaoAction.openModalCadastroEmpresaOrgao(true))
  }

  render() {
    const { loggingIn } = this.props
    const { classes } = this.props
    return (
      <Grid container className={classes.root}>
        <ModalRequestPasswordReset currentEmail={this.state.email} />
        <ModalChooseEmpresaOrgao />
        <ModalChooseTipoEmpresa />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          xl={5}
          component={Paper}
          elevation={6}
          square
        >
          <div className={classes.paper}>
            <img src={logo} width={350} style={{ marginBottom: 15 }} />
            <Typography component="h2" variant="h6">
              Fazer Login
            </Typography>
            <ValidatorForm
              className={classes.form}
              ref="form"
              onSubmit={this.handleSubmit}
            >
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                label="E-mail *"
                value={this.state.email}
                className={classes.textField}
                onInput={stringUtils.toInputLowerCase}
                onChange={this.handleChange('email')}
                id="email"
                name="email"
                autoComplete="email"
                validators={['required', 'isEmail']}
                errorMessages={[
                  'Você precisa informar o e-mail',
                  'E-mail inválido'
                ]}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                value={this.state.senha}
                onChange={this.handleChange('senha')}
                name="senha"
                label="Senha *"
                type={this.state.showPassword ? 'text' : 'password'}
                id="senha"
                autoComplete="current-senha"
                validators={['required']}
                errorMessages={['Opss! Você esqueceu da senha.']}
              />
              <Grid container justifyContent="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleChangeBox('showPassword')}
                      value="showPassword"
                      color="primary"
                    />
                  }
                  label="Mostrar senha"
                />
              </Grid>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#000' }}
                color="primary"
                disabled={loggingIn}
                type="submit"
                className={classes.submit}
                startIcon={<LockOpenIcon />}
                endIcon={loggingIn && <CircularProgress size={24} />}
              >
                {loggingIn ? 'Validando informações... ' : 'Entrar'}
              </Button>
            </ValidatorForm>
            <Grid container style={{ textAlign: 'center', justifyContent: 'center' }}>
              <Grid item xs={12}>
                <Link
                  component="button"
                  onClick={this.handleClickopenModalRequestPasswordReset}
                  variant="body1"
                  style={{ color: '#000', paddingTop: 12 }}
                >
                  {'Esqueceu a senha?'}
                </Link>
              </Grid>
            </Grid>
            <Grid container style={{ textAlign: 'left', justifyContent: 'left', paddingBottom: 10 }}>
              <Grid item xs={12}>
                <label>Não sou cliente</label>
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: '#000' }}
              color="primary"
              onClick={this.handleClickopenModalCadastroEmpresaOrgao}
              type="submit"
              className={classes.submit}
              startIcon={<AddCircleOutlineRoundedIcon />}
            >
              {'Quero me cadastrar'}
            </Button>
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: '#fff', marginTop: '10px', border: '1px solid #000' }}
              color="text"
              onClick={handleOpenPublicOrdensServico}
              type="button"
              className={classes.button}
              startIcon={<PersonPinCircleOutlinedIcon />}
            >
              {'Sou cidadão'}
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </Grid>
      </Grid>
    )
  }
}
Login.propTypes = {
  classes: PropTypes.object.isRequired
}

const handleOpenPublicOrdensServico = () => {
  history.push(`/ordemServico-publico/`);
}

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication
  return {
    loggingIn
  }
}
const connectedLoginPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Login))
)
export { connectedLoginPage as Login }

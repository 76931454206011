import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const dashboardAction = {
  getDashboard,
  getDashboardGraficoOrdensServico,
  clear,
  notIsLoading,
  isLoading
}

function getDashboard(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request())
    let apiEndpoint = 'dashboard' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(notIsLoading())
      })
      .catch((error) => {
        dispatch(failure(error))
        dispatch(notIsLoading())
      })
  }

  function request() {
    return {
      type: 'FETCHED_DASHBOARD_REQUEST'
    }
  }

  function success(dashboardResponse) {
    return {
      type: 'FETCHED_DASHBOARD_SUCCESS',
      dashboard: dashboardResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_FAILURE', error }
  }
}

function getDashboardGraficoOrdensServico() {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request())
    let apiEndpoint = 'dashboard/graficoOrdensServico';
    crudService
      .get(apiEndpoint)
      .then((response) => {      
        dispatch(success(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((error) => {
        dispatch(failure(error))
          dispatch(notIsLoading())
      })
  }
  function request() {
    return {
      type: 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_REQUEST'
    }
  }

  function success(ordensServico, totalOrdensServico) {
    return {
      type: 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_SUCCESS',
      ordensServico: ordensServico,
      totalOrdensServico: totalOrdensServico
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_FAILURE', error }
  }

}

export function clear() {
  return {
    type: 'DASHBOARD_CLEAR'
  }
}

export function isLoading() {
  return {
    type: 'DASHBOARD_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'DASHBOARD_NOTISLOADING'
  }
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../components/CustomButton/CustomButton'
import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { empresaAction, parametroAction } from '../../_actions'
import { styled } from '@mui/material/styles';

const CustomGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left'
    },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#000000',
        }
    }
}));

const CustomTypographyModal = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginTop: -10,
        marginBottom: -3,
        paddingTop: -10,
        paddingBottom: -8,
        paddingRight: 3,
        marginRight: 3,
        paddingLeft: 3,
        marginLeft: 3
    },
}));

const CustomTypographyTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontWeight: 'bold',
    textAlign: "left",
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
        fontSize: "15px",
        width: '85%',
        fontWeight: 'bold',
        textAlign: "left",
    },
}));

const CustomButtonModal = styled(Button)(({ theme }) => ({
    backgroundColor: '#000',
    position: 'relative',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginTop: 10,
        marginRight: 10,
        minWidth: 45,
        maxWidth: 50,
        fontSize: 12,
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
}));

const CustomIframe = styled('iframe')(({ theme }) => ({
    width: '100%',
    height: 450,
    objectFit: 'contain',
    padding: 3,
    justifycontent: 'right',
    borderRadius: 7,
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 450,
        objectFit: 'contain',
        padding: 3,
        justifycontent: 'center',
        borderRadius: 7,
    },
}));

export const EmpresaAceiteTermosModal = ({ modal, toggle, props }) => {

    const dispatch = useDispatch()
    const { classes, className, ...rest } = props
    const { match: { params } } = props;

    const isLoading = useSelector(
        (state) => state.empresa.isLoading
    )

    const [aceiteTermos, setAceiteTermos] = useState(false);

    const { empresa } = useSelector(
        (state) => state.empresa
      )

    useEffect(() => {
        dispatch(parametroAction.getTermoAceiteEmpresas())
        dispatch(empresaAction.getEmpresaSelect())
    }, [])

    const handleClose = () => {
        toggle();
    }

    const empresaUsuario = empresa?.find(em => props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa == parseInt(em.value) && em.excluido != true);

    const handleSubmitTermosUso = (event) => {

        let payload = {
            id: empresaUsuario?.value,
            aceiteTermos: aceiteTermos
        }

        dispatch(empresaAction.editEmpresaAceitaTermosUso(empresaUsuario?.value, payload, true))
        toggle()
    };

    return (
        <Dialog
            open={modal}
            fullWidth
            style={{ padding: 0, margin: 0 }}
            maxWidth='md'
            onClose={toggle}
        >
            <DialogTitle>
                <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <CustomTypographyTitle>
                            CONFIRMAÇÃO DE ACEITE DOS TERMOS DE USO
                        </CustomTypographyTitle>
                    </Grid>
                    <CustomButtonModal
                        variant="contained"
                        size="small"
                        onClick={() => handleClose()}>
                        <CloseIcon />
                    </CustomButtonModal>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ padding: 0, margin: 0 }}>
                <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
                    <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                            <CustomTypographyModal style={{ fontSize: 16, marginTop: 8 }} ><strong>Ao se cadastrar, você confirma que está de acordo com o Termo a seguir:</strong></CustomTypographyModal>
                        </CustomGrid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                            {
                                props.parametro.valor ?
                                    <CustomIframe
                                        src={props.parametro.valor}
                                        frameBorder="0"
                                        scrolling="yes"
                                    >
                                    </CustomIframe>
                                    : 'Sem contrato'
                            }
                        </CustomGrid>
                    </Grid>
                    <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                        <Grid item lg={12} md={12} xl={12} xs={12} >
                            <FormControlLabel
                                control={
                                    <CustomSwitch
                                        checked={aceiteTermos}
                                        onChange={() => setAceiteTermos(!aceiteTermos)}
                                    />
                                }
                                label="Eu li e aceito os termos presentes no Contrato listado acima"
                            />
                        </Grid>
                    </Grid>
                    {aceiteTermos &&
                        <CustomButton
                            onClick={(event) => handleSubmitTermosUso(event)}
                            variant="contained"
                            dark={true}
                            startIcon={<SaveIcon />}
                            endIcon={isLoading && <CircularProgress size={24} />}
                        >
                            {isLoading ? 'Salvando...' : 'Salvar'}
                        </CustomButton>
                    }
                </Card>
            </DialogContent>
        </Dialog >
    )
}

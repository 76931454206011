const initialState = {
  isLoading: false,
  id: 0,
  idNotificacao: '',
  idUsuario: '',
  usuario: null
}

export function notificacaoUsuario(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_NOTIFICACAO_USUARIO':
      return {
        ...state,
        notificacaoUsuario: action.notificacaoUsuario,
        totalRegistros: action.totalRegistros
      }
    case 'NOTIFICACAO_USUARIO_DETAIL':
      return action.notificacaoUsuario
    case 'NOTIFICACAO_USUARIO_UPDATED':
      return state
    case 'NOTIFICACAO_USUARIO_CLEAR':
      return initialState
    case 'NOTIFICACAO_USUARIO_CLEAR_ALL':
      return {
        ...state,
        notificacaoUsuario: []
      }
    case 'NOTIFICACAO_USUARIO_CLEAR_INFO':
      return {
        ...state,
        isLoading: false,
        id: 0,
        idNotificacao: '',
        idUsuario: '',
        usuario: null
      }
    case 'NOTIFICACAO_USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'NOTIFICACAO_USUARIO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'NOTIFICACAO_USUARIO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatRelativeTime } from 'utils';
import clsx from 'clsx'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  AppBar,
  Fade,
  Hidden,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import { authActions, ordemServicoOcorrenciaAction, ordemServicoAction } from '../../../../_actions';
import { SelectOrgao } from './components';
import logo from '../../../../img/rotorWhite.png';

const styles = (theme) => ({
  root: {
    boxShadow: 'none'
  },
  topBar: {
    color: 'inherit',
    textDecoration: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const ButtonStyledCenter = styled('button')({
  padding: '10px 20px',
  fontSize: '14px',
  border: 'none',
  cursor: 'pointer',
  backgroundColor: '#000', 
  color: '#fff', // Contraste com o fundo
  borderRadius: '8px', // Bordas arredondadas
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para dar destaque
  display: 'block',
  margin: '20px auto', // Centraliza o botão
  transition: 'background-color 0.3s ease, transform 0.3s ease',

  '&:hover': {
    transform: 'scale(1.03)'
  },
});

const SpanStyled = styled('span')({
  borderRadius: 8,
  paddingTop: 2,
  paddingBottom: 1,
  paddingRight: 3,
  paddingLeft: 3,
  color: '#fff',
  fontSize: 10,
  fontWeight: 400,
  marginRight: 4
});

const ButtonStyled = styled('button')({
  paddingLeft: 4,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 4,
  fontSize: 10,
  border: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: '#000'
});

const TableStyled = styled(Table)(() => ({
  border: 'none'
}));

const TableCellStyled = styled(TableCell)(() => ({
  fontSize: 10,
  border: 'none',
  alignContent: 'center',
  alignItems: 'center'
}));

const ToolTipFormatted = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 550,
    fontSize: 9,
    border: '1.2px solid #000',
  },
}));

const Topbar = ({ ...props }) => {
  const { className, onSidebarOpen, ...rest } = props;
  const { classes } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const logout = () => {
    dispatch(authActions.logout());
  };

  const { ordemServicoOcorrencia } = useSelector(
    (state) => state?.ordemServicoOcorrencia
  );

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleOpenLastsEvents = () => {
    let filterModel = {
      IdOrgao: idOrgao
    }

    dispatch(ordemServicoOcorrenciaAction.getOrdemServicoOcorrencia(filterModel));
    setOpen(!open);
  };

  const handleNavigation = (event, idOs) => {
    event.preventDefault();
    if (idOs) {
      window.open(`/ordemServico/${idOs}`, '_blank'); //abre em uma nova aba
      dispatch(ordemServicoAction.getOrdemServicoById(idOs));

      let filterModel = {
        IdOrgao: idOrgao
      }

      dispatch(ordemServicoOcorrenciaAction.getOrdemServicoOcorrencia(filterModel));
    }
  };

  const renderTooltip = () => {
    return (
      <ToolTipFormatted
        open={open}
        onClose={() => setOpen(false)}
        leaveTouchDelay
        leaveDelay={2000}
        onMouseClick={() => setOpen(true)}
        placement="bottom-end"
        TransitionComponent={Fade}
        title={
          ordemServicoOcorrencia && ordemServicoOcorrencia.length > 0 ? (
            <>
              {ordemServicoOcorrencia.map((o, index) => (
                <TableContainer
                  key={index}
                  style={{
                    marginTop: index !== 0 && 3,
                    border: '1px solid #ddd',
                    borderRadius: 5,
                  }}
                >
                  <TableStyled size="small">
                    <TableHead>
                      {index === 0 && (
                        <TableRow key={1}>
                          <TableCell colSpan={4}>
                            <Typography
                              color="inherit"
                              align="center"
                              style={{ fontSize: 11 }}
                            >
                              <b>ÚLTIMOS EVENTOS</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                    <TableBody style={{ border: 'none' }}>
                      {o.orgao &&
                        <TableRow key={o.orgao?.nome || o.data} style={{ border: 'none' }}>
                          <TableCellStyled style={{ backgroundColor: '#e6e6e6' }} colSpan={2}>
                            {o.orgao?.nome?.toUpperCase()}
                          </TableCellStyled>
                        </TableRow>
                      }
                      <TableRow key={o.id} style={{ border: 'none' }}>
                        <TableCell style={{ border: 'none' }}>
                          <Typography sx={{ fontSize: 11 }} color="inherit" align="left">
                            {o.data ? formatRelativeTime(o.data) : '-'} - {o.descricao && o.descricao != "" ? o.descricao?.length >= 120 ? (o.descricao?.substring(0, 120)?.toUpperCase() + '...') : o.descricao.toUpperCase() : '-'}
                          </Typography>
                          <SpanStyled style={{ backgroundColor: '#2196F3' }}>
                            <strong>#O.S {o.ordemServico.id}</strong>
                          </SpanStyled>
                          {o.empresa && (
                            <SpanStyled style={{ backgroundColor: '#4CAF50' }}>
                              <strong>{o.empresa?.razaoSocial?.toUpperCase()}</strong>
                            </SpanStyled>
                          )}
                          {o.usuario && (
                            <SpanStyled style={{ backgroundColor: '#9E9E9E' }}>
                              <strong>{o.usuario?.nome?.toUpperCase()}</strong>
                            </SpanStyled>
                          )}
                          {o.valor && (
                            <SpanStyled style={{ backgroundColor: '#EF5350' }}>
                              <strong>R${' '}{o.valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</strong>
                            </SpanStyled>
                          )}
                        </TableCell>
                        <TableCell align="right" style={{ border: 'none' }}>
                          <ButtonStyled onClick={(e) => handleNavigation(e, o.idOrdemServico)} size="small">
                            VER MAIS
                          </ButtonStyled>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </TableStyled>
                </TableContainer>
              ))
              }
              {/* não mostra mais o botão para empresa, já que a mesma não tem acesso ao relatório*/}
              {!authActions.isEmpresa() ?
                <ButtonStyledCenter onClick={() => window.open(`/relatorioOcorrenciasOrdemServico`, '_blank')} size="small">
                  VER TODAS AS NOTIFICAÇÕES
                </ButtonStyledCenter>
                : null}
            </>
          ) : (
            <Typography color="inherit" align="center" style={{ fontSize: 11, padding: 7 }}>
              Não há registros para exibir
            </Typography>
          )}
      >
        <IconButton style={{ marginLeft: 10 }} color="inherit" onClick={handleOpenLastsEvents}>
          <NotificationsNoneOutlinedIcon />
        </IconButton>
      </ToolTipFormatted>
    );
  };


  return (
    <AppBar
      style={{ backgroundColor: '#25261C' }}
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/" className={classes.topBar}>
          <img width={60} alt="Logo Rotor" src={logo} />
        </RouterLink>
        <div style={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <div className={classes.selectLoja}>
            <SelectOrgao disabled={false} />
          </div>
          {(authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) &&
            renderTooltip()
          }
          <Tooltip title="Sair">
            <IconButton style={{ marginLeft: 10 }} color="inherit" onClick={logout}>
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', left: 0, right: 0 }}>
            <SelectOrgao disabled={false} />
          </div>
          {(authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) &&
            renderTooltip()
          }
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  type: PropTypes.string
};

const mapStateToProps = (state) => {
  return state;
};
const TopbarPage = withRouter(connect(mapStateToProps, null, null)(withStyles(styles)(Topbar)));
export default TopbarPage;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  ordemServicoAction
} from '../../_actions'
import { toast } from 'react-toastify';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

export const OrdemServicoMotivoModal = ({ modal, toggle, props, idOrdemServico }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleChange = (prop) => (event) => {
    dispatch(ordemServicoAction.onChangeProps(prop, event))
  }

  const handleClose = () => {
    toggle();
  }

  const handleSubmit = () => {
    let payload = { motivoExclusao: props.ordemServico.motivoExclusao }

    if (props.ordemServico.motivoExclusao && props.ordemServico.motivoExclusao != null && props.ordemServico.motivoExclusao != '') {
      dispatch(ordemServicoAction.deleteOrdemServicoById(idOrdemServico, idOrgao, payload));
      toggle();
    } else {
      toast.error('É necessário informar um motivo para poder excluir a Ordem de Serviço')
    }

  };

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Excluir Ordem de Serviço'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Alterar o Status de uma Ordem de Serviço"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <TextValidator
                className={classes.textField}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                id="motivo"
                label="Informe o Motivo"
                margin="normal"
                onInput={stringUtils.toInputUppercase}
                onChange={handleChange('motivoExclusao')}
                value={props.ordemServico.motivoExclusao}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.ordemServico.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                >
                  {props.ordemServico.isLoading ? 'Excluindo ordem de serviço...' : 'Excluir'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}

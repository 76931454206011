import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const ordemServicoOcorrenciaAction = {
  getOrdemServicoOcorrencia,
  onChangeProps,
  clear,
  clearDetail,
  notIsLoading,
  isLoading
}

function getOrdemServicoOcorrencia(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOcorrencias/ultimasOcorrenciasSistema' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObrasOcorrenciasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

export function clear() {
  return {
    type: 'ORDEM_SERVICO_OCORRENCIA_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'ORDEM_SERVICO_OCORRENCIA_CLEAR_DETAIL'
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'obraOcorrenciaItem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

export function changeObrasOcorrenciasList(ordemServicoOcorrencia, totalRows) {
  return {
    type: 'FETCHED_ALL_ORDEM_SERVICO_OCORRENCIA',
    ordemServicoOcorrencia: ordemServicoOcorrencia,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'ORDEM_SERVICO_OCORRENCIA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function isLoading() {
  return {
    type: 'ORDEM_SERVICO_OCORRENCIA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ORDEM_SERVICO_OCORRENCIA_NOTISLOADING'
  }
}

import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { cnpjMask } from '../../utils'
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const CustomPage = styled(Page)(() => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioEmpresaPorOrgaoPrint = (props) => {

  const [ready, setReady] = React.useState(false);
  const { relatorio } = props.relatorio

  useEffect(() => {

  }, [props.relatorio]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header
                title='Relatório de Empresas por Órgão'
                info={<Text>ROTOR - FABRICAÇÃO, MANUTENÇÃO, REPARAÇÃO E FORNECIMENTO LTDA - 40.691.238/0001-25</Text>}
              />
              <View style={[styles.row, { marginTop: 60 }]} key={0} >
                <View style={[styles.header, { width: 250 }]}>
                  <Text>ÓRGÃO</Text>
                </View>
                <View style={[styles.header, { width: 400 }]}>
                  <Text>RAZÃO SOCIAL</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>CNPJ</Text>
                </View>
              </View>
              {
                relatorio.length > 0 ? relatorio.map(n => {
                  var retorno = (
                    <View style={[styles.row]} key={n.id} >
                      <View style={[styles.cell, { width: 250 }]}>
                        <Text>{n.orgao ? n.orgao.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 400 }]}>
                        <Text>{n.nomeEmpresa ? n.nomeEmpresa.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 200 }]}>
                        <Text>{n.cnpj ? cnpjMask(n.cnpj) : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioEmpresaPorOrgaoPrintPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioEmpresaPorOrgaoPrint)));
export { connectedRelatorioEmpresaPorOrgaoPrintPage as RelatorioEmpresaPorOrgaoPrint };

const initialState = {
  isLoading: false,
  id: 0,
  idNotificacao: '',
  filtro: '',
  notificacoesFiltrosItens: []
}

export function notificacaoFiltro(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_NOTIFICACAO_FILTRO':
      return {
        ...state,
        notificacaoFiltro: action.notificacaoFiltro,
        totalRegistros: action.totalRegistros
      }
    case 'NOTIFICACAO_FILTRO_DETAIL':
      return action.notificacaoFiltro
    case 'NOTIFICACAO_FILTRO_UPDATED':
      return state
    case 'NOTIFICACAO_FILTRO_CLEAR':
      return initialState
    case 'NOTIFICACAO_FILTRO_CLEAR_ALL':
      return {
        ...state,
        notificacaoFiltro: []
      }
    case 'NOTIFICACAO_FILTRO_CLEAR_INFO':
      return {
        ...state,
        isLoading: false,
        id: 0,
        idNotificacao: '',
        filtro: '',
      }
    case 'NOTIFICACAO_FILTRO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'NOTIFICACAO_FILTRO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'NOTIFICACAO_FILTRO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

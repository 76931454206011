import { crudService } from '../_services/'

export const tipoFotoAction = {
  getTiposFotoSelect,
  changeDetailsTipoFoto
}

function getTiposFotoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposFotos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposFotosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsTipoFoto(tipoFoto) {
  return (dispatch) => {
    if (tipoFoto) {
      dispatch(onChangeDetailsTipoFoto(tipoFoto))
    } else {
      clear()
    }
  }
}

export function clear() {
  return {
    type: 'TIPO_FOTO_CLEAR'
  }
}

export function onChangeDetailsTipoFoto(tipoFoto) {
  return [
    {
      type: 'CHANGE_DETAILS_TIPO_FOTO',
      id: tipoFoto.id,
      nome: tipoFoto.nome
    }
  ]
}

export function changeTiposFotosList(tipoFoto) {
  return {
    type: 'FETCHED_ALL_TIPO_FOTO',
    tipoFoto: tipoFoto
  }
}


import { crudService } from '../_services'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const empresaAnexoAction = {
    getEmpresaAnexo,
    getEmpresaAnexoSelect,
    getEmpresaAnexoById,
    onChangeProps,
    editEmpresaAnexoInfo,
    editEmpresaAnexoAprova,
    editEmpresaAnexoRecusa,
    createEmpresaAnexo,
    deleteEmpresaAnexoById,
    clear,
    clearDetail
}

function getEmpresaAnexo(filterModel) {
    return (dispatch) => {
        let apiEndpoint = 'empresasAnexos' + stringUtils.serialize(filterModel);
        crudService
            .get(apiEndpoint)
            .then((response) => {
                dispatch(changeEmpresaAnexosList(response.data.items, response.data.totalRows))
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

function getEmpresaAnexoSelect() {
    return (dispatch) => {
        let apiEndpoint = 'empresasAnexos/select'
        crudService
            .get(apiEndpoint)
            .then((response) => {
                if (response.data) {
                    dispatch(changeEmpresaAnexosList(response.data))
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
}


function editEmpresaAnexoAprova(payload) {
    return (dispatch) => {
        dispatch(isLoading())
        dispatch(request(payload))
        let apiEndpoint = 'empresasAnexos/aprova/' + payload.id
        crudService
            .put(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status === 200) {
                    dispatch(notIsLoading())

                    let filterModel = {
                        Page: 0,
                        Term: '',
                        IdEmpresa: payload.idEmpresa
                    };

                    dispatch(getEmpresaAnexo(filterModel, true))
                } else {
                    dispatch(notIsLoading())
                }
            })
            .catch((err) => {
                dispatch(failure(err))
                dispatch(notIsLoading())
                // toast.error('Oops! Erro ao alterar Obra! 😥')
            })
    }

    function failure(error) {
        return { type: 'EDIT_OBRA_MEDICAO_FAILURE', error }
    }
}

function editEmpresaAnexoRecusa(payload) {
    return (dispatch) => {
        dispatch(isLoading())
        dispatch(request(payload))
        let apiEndpoint = 'empresasAnexos/recusa/' + payload.id
        crudService
            .put(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status === 200) {
                    dispatch(notIsLoading())

                    let filterModel = {
                        Page: 0,
                        Term: '',
                        IdEmpresa: payload.idEmpresa
                    };

                    dispatch(getEmpresaAnexo(filterModel, true))
                } else {
                    dispatch(notIsLoading())
                }
            })
            .catch((err) => {
                dispatch(failure(err))
                dispatch(notIsLoading())
                // toast.error('Oops! Erro ao alterar Obra! 😥')
            })
    }
}

function createEmpresaAnexo(payload) {
    return (dispatch) => {
        dispatch(isLoading())
        dispatch(request(payload))
        let apiEndpoint = 'empresasAnexos'
        crudService
            .post(apiEndpoint, payload)
            .then((response) => {
                if (response.request != undefined && response.request.status === 200) {
                    toast.success('Feito! Cadastro realizado com sucesso! 😎')
                    dispatch(clear())
                    dispatch(notIsLoading())

                    var ret = JSON.parse(response.request.responseText);
                    let filterModel = {
                        IdObra: ret.key
                    };

                    dispatch(getEmpresaAnexo(filterModel, true));
                } 
            })
            .catch((err) => {
                // console.log('err', err)
                dispatch(failure(err))
                dispatch(notIsLoading())
            })
    }

    function request(payload) {
        return { type: 'CREATE_EMPRESA_ANEXO_REQUEST', payload }
    }

    function failure(error) {
        return { type: 'CREATE_EMPRESA_ANEXO_FAILURE', error }
    }
}

export function clear() {
    return {
        type: 'EMPRESA_ANEXO_CLEAR'
    }
}

export function clearDetail() {
    return {
        type: 'EMPRESA_ANEXO_CLEAR_DETAIL'
    }
}

function request(payload) {
    return { type: 'EDIT_EMPRESA_ANEXO_REQUEST', payload }
}

function failure(error) {
    return { type: 'EDIT_EMPRESA_ANEXO_FAILURE', error }
}

function editEmpresaAnexoInfo(id, payload) {
    return (dispatch) => {
        dispatch(isLoading())
        dispatch(request(payload))
        let apiEndpoint = 'empresasAnexos/' + id
        crudService
            .put(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status === 200) {
                    dispatch(notIsLoading())

                    var ret = JSON.parse(response.request.responseText);
                    let filterModel = {
                        IdObra: ret.key
                    };
                    dispatch(getEmpresaAnexo(filterModel, true));

                } else {
                    dispatch(notIsLoading())
                }
            })
            .catch((err) => {
                dispatch(failure(err))
                dispatch(notIsLoading())
            })
    }

    function request(payload) {
        return { type: 'EDIT_EMPRESA_ANEXO_REQUEST', payload }
    }

    function failure(error) {
        return { type: 'EDIT_EMPRESA_ANEXO_FAILURE', error }
    }
}

function getEmpresaAnexoById(id) {
    return (dispatch) => {
        let apiEndpoint = 'empresasAnexos/' + id
        crudService.get(apiEndpoint).then((response) => {
            dispatch(editEmpresaAnexosDetails(response.data))

            let filterModel = {
                IdObra: response.data.idEmpresa
            };
            dispatch(getEmpresaAnexo(filterModel, true));
        })
    }
}

function onChangeProps(props, event) {
    return (dispatch) => {
        if (props === 'imagem') {
            dispatch(handleOnChangeProps(props, event))
        } else {
            dispatch(handleOnChangeProps(props, event.target.value))
        }
    }
}

function deleteEmpresaAnexoById(id) {
    return (dispatch) => {
        dispatch(isLoading())
        let apiEndpoint = 'empresasAnexos/' + id
        crudService.deleteDetail(apiEndpoint).then((response) => {
            dispatch(deleteEmpresaAnexosDetails())
            dispatch(notIsLoading())

            let filterModel = {
                Situacao: 0
            };

            dispatch(getEmpresaAnexo(filterModel, true));

        })
    }
}
export function changeEmpresaAnexosList(empresaAnexo, totalRows) {
    return {
        type: 'FETCHED_ALL_EMPRESA_ANEXO',
        empresaAnexo: empresaAnexo,
        totalRegistros: totalRows
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: 'EMPRESA_ANEXO_HANDLE_ON_CHANGE',
        props: props,
        value: value
    }
}
export function editEmpresaAnexosDetails(empresaAnexo) {
    return {
        type: 'EMPRESA_ANEXO_DETAIL',
        id: empresaAnexo.id,
        idTipoAnexo: empresaAnexo.idTipoAnexo,
        caminhoAnexo: empresaAnexo.caminhoAnexo,
        idEmpresa: empresaAnexo.idEmpresa,
        empresa: empresaAnexo.empresa,
        excluido: empresaAnexo.excluido,
        empresaAnexo: empresaAnexo.empresaAnexo
    }
}
export function createUserInfo() {
    return {
        type: 'EMPRESA_ANEXO_CREATED_SUCCESSFULLY'
    }
}
export function deleteEmpresaAnexosDetails() {
    return {
        type: 'DELETED_EMPRESA_ANEXO_DETAILS'
    }
}
export function isLoading() {
    return {
        type: 'EMPRESA_ANEXO_ISLOADING'
    }
}

export function notIsLoading() {
    return {
        type: 'EMPRESA_ANEXO_NOTISLOADING'
    }
}


import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { empenhoAction } from '../../_actions'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Grid,
  Paper,
  Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { styled } from '@mui/material/styles';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomGrid2 = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 4
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const EmpenhoSummaryCard = (props) => {

  const { match: { params } } = props;
  const { idEmpenho } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(empenhoAction.clear())
    if (idEmpenho) {
      dispatch(empenhoAction.getEmpenhoById(idEmpenho))
    }
  }, [])

  //precisa somar o valor de todas as ordens de serviço dentro do empenho, pois pode ter
  //mais de uma por empenho
  const calcValorTotalEmpenho = (idEmpenho) => {
    return props.empenho?.ordensServicosEmpenhos?.reduce((subtotal, os) => {
      const valorOrcamento = os.ordemServico?.ordensServicosOrcamentos?.reduce((subSubtotal, orc) => {
        return subSubtotal + (orc.valor || 0);
      }, 0);
      return subtotal + valorOrcamento;
    }, 0);
  };  

  return (
    <Grid item lg={12} md={12} xl={12} xs={12}>
      {idEmpenho &&
        <Paper elevation={3} style={{ marginBottom: 6, backgroundColor: '#f7f7f7', paddingBottom: 4 }}>
          <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={8} lg={8} md={8} xs={12} align="left">
              <CustomTypography variant="overline" style={{ fontSize: 15 }} ><strong>RESUMO DO EMPENHO</strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={4} lg={4} md={4} xs={12} align="right">
              <CustomTypography variant="overline"><strong>DATA CADASTRO: </strong>{props.empenho.dataCadastro ? moment(props.empenho.dataCadastro).format("DD/MM/yyyy") : ''}</CustomTypography>
            </CustomGrid>
          </Grid>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>CÓDIGO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">#{props.empenho?.id}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>ÓRGÃO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="left">
              <CustomTypography variant="overline">{props.empenho.orgao?.nome?.toUpperCase()}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>EMPRESA: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="left">
              <CustomTypography variant="overline">{props.empenho.empresa?.razaoSocial?.toUpperCase()}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>VALOR: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="left">
              <CustomTypography variant="overline">R$ {props.empenho?.valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) || calcValorTotalEmpenho(idEmpenho)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
        </Paper>
      }
    </Grid>
  )
}

EmpenhoSummaryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  idEmpenho: PropTypes.number.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedEmpenhoSummaryCardPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(EmpenhoSummaryCard))
)
export { connectedEmpenhoSummaryCardPage as EmpenhoSummaryCard }

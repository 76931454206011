import React from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@mui/material'
import { options } from './options'
import { useSelector } from 'react-redux'
import { getCor } from '../../../../utils'

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoOrdensServicoStatusChartCard = (props) => {

  const { className, ...rest } = props
  const graficoOrdensServico = useSelector((state) => state.dashboard ? state.dashboard.dashboard.graficoOrdensServico : undefined)
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  const generateData = () => {
    if (graficoOrdensServico) {
      const data = {
        labels: graficoOrdensServico.options, //labels
        datasets: [
          {
            label: 'Ordens de serviço criadas',
            data: graficoOrdensServico.series[0],  //dados
            backgroundColor: graficoOrdensServico.options.map((o) => getCor(o)),
            borderColor: graficoOrdensServico.options.map((o) => getCor(o)),
            borderWidth: 2
          },
        ],
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={<Typography variant="subtitle1" align="center">
          Ordens de Serviço Por Status
        </Typography>}
      />
      <Divider />
      <CardContent style={{ height: graficoOrdensServico?.options != undefined && graficoOrdensServico?.options.length > 0 ? 400 : 30, alignContent: 'center' }}>
        {graficoOrdensServico?.options != undefined && graficoOrdensServico?.options.length > 0 ?
          <Pie data={generateData} options={options} />
          :
          <Typography align="center">
            Nenhum dado a ser exibido
          </Typography>}
      </CardContent>
      <Divider />
    </Card>
  )
}

GraficoOrdensServicoStatusChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoOrdensServicoStatusChartCard

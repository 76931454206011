import React, { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid, IconButton, Paper, Typography, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { MessageLeft, MessageRight } from "./ordemServicoOcorrenciaMensagens.component";
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from 'utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "120vw",
      height: "80vh",
      maxWidth: "800px",
      maxHeight: "700px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative"
    },
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    messagesBody: {
      width: "calc(100% - 20px)",
      margin: 10,
      paddingTop: 12,
      overflowY: "scroll",
      height: "calc(100% - 80px)"
    }
  })
);

export default function OrdemServicoOcorrenciaChat({ props }) {
  const classes = useStyles();
  const { ordemServico, className, ...rest } = props
  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper} zDepth={2} >
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5 }}>
          <Typography variant="h6" align="center">
            <b>Registro de Ocorrências</b>
          </Typography>
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        <Paper id="style-1" className={classes.messagesBody}>
          {ordemServico && ordemServico.ordensServicosOcorrencias && ordemServico.ordensServicosOcorrencias.length > 0 ?
            ordemServico.ordensServicosOcorrencias.map(o => {
              return (
                <>
                  {o.idEmpresa != null && o.empresa && o.empresa.razaoSocial != '' ?
                    <MessageLeft
                      message={o.descricao}
                      date={o.data}
                      user={o.usuario ? o.usuario.nome : ''}
                      displayName={o.empresa.razaoSocial}
                      attachment={o.ordensServicosOcorrenciasAnexos}
                    />
                    : o.idOrgao != null && o.orgao && o.orgao.nome != '' ?
                      <MessageRight
                        message={o.descricao}
                        user={o.usuario ? o.usuario.nome : ''}
                        date={o.data}
                        displayName={o.orgao.nome}
                        attachment={o.ordensServicosOcorrenciasAnexos}
                      />
                      : null
                  }
                </>
              )
            })
            : 'Não há ocorrências registradas para esta Ordem de Serviço'}

        </Paper>
        {
          (modalHelp ?
            <HelpModal
              toggle={toggleModalHelp}
              modal={modalHelp}
              props={props}
              tela={MANUAIS_CATEGORIAS.ABA_ORDEM_SERVICO_OCORRENCIA}
            />
            :
            null
          )
        }
      </Paper>
    </div>
  );
}

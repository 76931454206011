import React from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const CarouselImage = ({ images, openCarousel, handleClose }) => {
  return (
    <Dialog
      open={openCarousel}
      aria-labelledby="Carrossel de fotos"
      maxWidth="lg"
      fullWidth
    >
      <Button onClick={handleClose} style={{ position: 'absolute', right: 0, top: 10, zIndex: 99999 }}>
        <CloseIcon />
      </Button>
      <Carousel height='90vh' autoPlay={false} indicators={false} fullHeightHover navButtonsAlwaysVisible>
        {images?.length > 0
          ? images.map((image, index) => {
            return (
              <Box display='flex' justifyContent='center' alignItems='center' height='90vh' key={index}>
                <img src={image.caminhoFoto} style={{ maxHeight: '90vh', maxWidth: '90vw', objectFit: 'contain' }} />
              </Box>
            )
          })
          :
          <Box display='flex' justifyContent='center' alignItems='center' height='90vh'>
            <Typography variant="p">Sem fotos para exibir</Typography>
          </Box>
        }
      </Carousel>
    </Dialog>
  );
}

export default CarouselImage;

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  Button,
  Chip,
  Grid,
  Typography
} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { AccessControl } from '../../components/Utils/AccessControl'
import Stars from '../../components/Stars/Stars';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getNomeEvento, getNomeUltimoEvento } from 'utils'

export const OrdemServicoModalEventos = ({ modal, toggle, props, eventos, ordemServico }) => {

  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const isLoading = useSelector(
    (state) => state.usuarioOrgao.isLoading
  )

  useEffect(() => {
  }, [props.ordemServico.isLoading, isLoading])

  const calculateTimeDifference = (previousDate, currentDate) => {
    const diffInMinutes = moment(currentDate).diff(moment(previousDate), 'minutes');

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutos`;
    } else {
      const diffInHours = moment(currentDate).diff(moment(previousDate), 'hours');
      if (diffInHours < 24) {
        return `${diffInHours} horas`;
      } else {
        const diffInDays = moment(currentDate).diff(moment(previousDate), 'days');
        return `${diffInDays} dias`;
      }
    }
  };

  const handleClose = () => {
    toggle();
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      maxWidth="sm"
      onClose={toggle}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle style={{ paddingBottom: 0, marginBottom: 0 }}>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={1} style={{ padding: 0, margin: 0 }}>
          <AccessControl
            rule={'ordensServico.list'} //permissão necessária para acessar conteúdo
            yes={() => (
              <Grid item lg={12} md={12} xl={12} xs={12} align="right">
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  endIcon={<CloseIcon />}
                  onClick={() => handleClose()}
                >
                  Fechar
                </Button>
              </Grid>
            )}
          />
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        {eventos && eventos.length > 0 ? eventos.map((e, index) => {
          return (
            <Timeline position="alternate" style={{ marginTop: 4, marginBottom: 6 }} key={index}>
              <TimelineItem >
                <TimelineOppositeContent color="text.secondary" style={{ display: 'flex', flexDirection: 'column' }}>
                  {index > 0 && (
                    <span style={{ fontSize: 11, justifyContent: 'end', marginBottom: 8, marginTop: -20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <AccessTimeIcon style={{ fontSize: 11, marginRight: 1 }} /> {calculateTimeDifference(eventos[index - 1].data, e.data)}
                    </span>
                  )}
                  <span>{moment(e.data).format("DD/MM/YYYY HH:mm")}</span>
                  <Typography variant="subtitle2">{e.usuario?.nome?.toUpperCase()}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: '#000' }} />
                  {e.tipo != "7" && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography>{getNomeEvento(e.tipo)}</Typography>
                  <div key={e.id}>
                    {(getNomeEvento(e.tipo) == "EM EXECUÇÃO" || getNomeEvento(e) == "CONCLUÍDA") && ordemServico.empresa && (
                      <>
                        <Chip label={ordemServico.empresa.razaoSocial} />
                        <br />
                      </>
                    )}
                    {getNomeEvento(e.tipo) == "AVALIADA" &&
                      (ordemServico.avaliacao != 0 && <Stars nota={ordemServico.avaliacao} avaliacoes={1} timeline={true} />)
                    }
                  </div>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          )
        })
          : 'Não há eventos para exibir'
        }
      </DialogContent>
    </Dialog >
  )
}

const initialState = {
    loading: false,
    foto: [],
    open: false,
    id: '',
    nome: '',
    excluido: false,
}

export function tipoAnexo(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_TIPO_ANEXO':
            return {
                ...state,
                tipoAnexo: action.tipoAnexo
            }
        default:
            return state
    }
}
import React, { createContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ordemServicoAction } from '_actions'

export const ContextFiltros = createContext();

export const ContextFiltrosProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState([{ text: 'TODAS', value: '8' }]);
  const [state, setState] = useState([]);
  const [direction] = useState('desc');
  const [order] = useState('data');
  const [typeVehicle, setTypeVehicle] = useState([]);
  const [typeService, setTypeService] = useState([]);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleClearFilters = () => {
    setStatus([{ text: 'NENHUM', value: '10' }]);
    setState([]);
    setTypeVehicle([]);
    setTypeService([]);

    // Chama a função fetchData logo após limpar os filtros
    const filterModel = {
      Page: 0,
      Limit: 10,
      Term: '',
      Status: '8',
      Order: order,
      Direction: direction,
      IdOrgao: (idOrgao && idOrgao != '' && idOrgao)
    };

    dispatch(ordemServicoAction.getOrdemServico(filterModel, true));
  }

  return (
    <ContextFiltros.Provider value={{
      status,
      setStatus,
      state,
      setState,
      typeVehicle,
      setTypeVehicle,
      typeService,
      setTypeService,
      handleClearFilters
    }}>
      {children}
    </ContextFiltros.Provider>
  );
};

import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import { Header, Footer } from '../../_reports';
import { useLocation } from 'react-router-dom';
import { TIPO_LOG } from '../../utils'
import { styled } from '@mui/material/styles';
import moment from 'moment'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const CustomPage = styled(Page)(() => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioLogsSistemaPrint = (props) => {

  const [ready, setReady] = React.useState(false);
  const { relatorio } = props.relatorio

  useEffect(() => {

  }, [props.relatorio]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document onRender={onRendered} width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header
                title='Relatório Logs Sistema'
                info={<Text>ROTOR - FABRICAÇÃO, MANUTENÇÃO, REPARAÇÃO E FORNECIMENTO LTDA - 40.691.238/0001-25</Text>}
              />
              <View style={[styles.row, { marginTop: 60 }]} key={Math.random()} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>DATA</Text>
                </View>
                <View style={[styles.header, { width: 50 }]}>
                  <Text>CÓDIGO</Text>
                </View>
                <View style={[styles.header, { width: 60 }]}>
                  <Text>TIPO</Text>
                </View>
                <View style={[styles.header, { width: 140 }]}>
                  <Text>TABELA</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>CAMPO</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>USUÁRIO</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>VALOR ANTIGO</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>VALOR NOVO</Text>
                </View>
              </View>
              {
                relatorio.length > 0 ? relatorio.map(n => {
                  var retorno = (
                    <View style={[styles.row]} key={n.id} wrap={false}>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.dataHora ? moment(n.dataHora).format('DD/MM/yyyy') : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 50 }]}>
                        <Text>{n.chave ? n.chave : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 60 }]}>
                        <Text>{TIPO_LOG[n.tipo]}</Text>
                      </View>
                      <View style={[styles.cell, { width: 140 }]}>
                        <Text>{n.tabela ? n.tabela.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.campo ? n.campo.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 200 }]}>
                        <Text>{n.usuario ? n.usuario.nome?.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.valorAntigo}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text> {n.valorNovo}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioLogsSistemaPrintPage = withRouter(connect(mapStateToProps, null, null)(RelatorioLogsSistemaPrint));
export { connectedRelatorioLogsSistemaPrintPage as RelatorioLogsSistemaPrint };

import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { notificacaoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  FormControlLabel,
  TablePagination,
  Skeleton
} from '@mui/material'
import { withStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import EditIcon from '@mui/icons-material/Edit'
import { withRouter } from 'react-router-dom'
import { MANUAIS_CATEGORIAS, history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: '13px !important'
  }
})

const Notificacao = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { notificacao } = props.notificacao
  const { isLoading } = props.notificacao
  const { totalRegistros } = props.notificacao

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('dataHora');
  const [direction] = useState('desc');
  const [automatic, setAutomatic] = useState(false);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        Automatica: automatic
      };

      dispatch(notificacaoAction.getNotificacao(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, automatic]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [ready, props.notificacao.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta notificação?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.notificacao.isLoading,
      confirmationText: props.notificacao.isLoading ? 'Excluindo notificação...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(notificacaoAction.deleteNotificacaoById(id))
    })
  }

  const handleDuplicate = (id) => {
    confirm({
      title: 'Você deseja duplicar esta notificação?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, duplicar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(notificacaoAction.duplicateNotificacao(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Notificações">
      <AccessControl
        rule={'notificacoes'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== notificacao && notificacao.length > 0 ? (
              <TitleContent
                rule={'notificacoes.create'}
                length={notificacao.length}
                subTitle={'GERÊNCIA DE NOTIFICAÇÕES'}
                title={'Lista de Notificações'}
                href={'/notificacao'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_NOTIFICACAO}
              />
            ) : (
              <TitleContent
                rule={'notificacoes.create'}
                length={0}
                subTitle={'GERÊNCIA DE NOTIFICAÇÕES'}
                title={'Lista de Notificações'}
                href={'/notificacao'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_NOTIFICACAO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <Grid item xl={9} lg={9} md={9} xs={12} align="right">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => setAutomatic(event.target.checked)}
                      value="showNotificationAutomatic"
                      color="primary"
                    />
                  }
                  label="Exibir também as notificações automáticas"
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data/Hora</TableCell>
                    <TableCell>Data/Hora Envio</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== notificacao && notificacao.length
                    ? notificacao.map((n) => {
                      return (
                        <TableRow hover key={n.id} className={classes.textField}>
                          <TableCell component="th" scope="row">
                            {moment(n.dataHora).format("DD/MM/YYYY HH:mm")}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {(n.dataHoraEnvio ? moment(n.dataHoraEnvio).format("DD/MM/YYYY HH:mm") : '')}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.titulo}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'notificacoes.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Duplicate"
                                  component="a"
                                  onClick={() => handleDuplicate(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Duplicar">
                                    <Tooltip>
                                      <FileCopyIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'notificacoes.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/notificacao/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title="Editar">
                                    <Tooltip>
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />

                            <AccessControl
                              rule={'notificacoes.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />

    </Page>
  )
}
Notificacao.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    notificacao: state.notificacao
  }
}
const connectedNotificacaoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Notificacao))
)
export { connectedNotificacaoPage as Notificacao }

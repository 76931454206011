import React from 'react';
import Typography from '@mui/material/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'


const Stars = ({ avaliacoes, nota, timeline }) => {
  const fullStars = Math.floor(nota);
  const decimalPart = nota - fullStars;

  const starArray = Array.from({ length: 5 });

  return (
    <>
      {avaliacoes > 0 ? (
        <>
          {starArray.map((_, index) => {
            if (index < fullStars) {
              // Full star
              return (
                <FontAwesomeIcon
                  icon={faStar}
                  key={index}
                  style={{ color: '#F39C12' }}
                />
              );
            } else if (index === fullStars && decimalPart > 0) {
              // Partial star
              return (
                <span key={index}>
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{ color: '#F39C12', clipPath: 'polygon(0 0, 50% 0, 50% 100%, 0% 100%)', marginRight: -8 }}
                  /><FontAwesomeIcon
                    icon={faStar}
                    style={{ color: '#B0BEC5', clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)', marginLeft: -8 }}
                  />
                </span>
              );
            } else {
              // Empty star
              return (
                <FontAwesomeIcon
                  icon={faStar}
                  key={index}
                  style={{ color: '#B0BEC5', marginLeft: 0, marginRight: 0 }}
                />
              );
            }
          })}
          {!timeline &&
            <Typography style={{ fontSize: '12px' }}>
              {avaliacoes > 0 && avaliacoes != 1 ? ` (${avaliacoes} avaliações)` : avaliacoes == 1 ? `(1 avaliação)` : ''}
            </Typography>
          }
        </>
      ) : (
        <>
          {starArray.map((_, index) => (
            <FontAwesomeIcon
              icon={faStar}
              key={index}
              style={{ color: '#B0BEC5' }}
            />
          ))}
          <Typography style={{ fontSize: 12 }}>(Sem Avaliações)</Typography>
        </>
      )}
    </>
  );

};

export default Stars;

const initialState = {
  totalRegistros: 0,
  isLoading: false,
  manual: [],
  id: '',
  nome: '',
  descricao: '',
  categoria: 0,
  link: '',
  tiposUsuariosManuais: [],
  excluido: ''
}

export function manual(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_MANUAL_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_MANUAL_SUCCESS':
      return {
        ...state,
        id: action.manual.id,
        isLoading: false,
        nome: action.manual.nome,
        descricao: action.manual.descricao,
        categoria: action.manual.categoria,
        link: action.manual.link,
        tiposUsuariosManuais: action.manual.tiposUsuariosManuais,
        excluido: action.manual.excluido
      }
    case 'CREATE_MANUAL_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_MANUAL_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_MANUAL_SUCCESS':
      return {
        ...state,
        id: action.manual.id,
        isLoading: false,
        nome: action.manual.nome,
        descricao: action.manual.descricao,
        categoria: action.manual.categoria,
        link: action.manual.link,
        tiposUsuariosManuais: action.manual.tiposUsuariosManuais,
        excluido: action.manual.excluido
      }
    case 'EDIT_MANUAL_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_MANUAL':
      return {
        ...state,
        manual: action.manual,
        totalRegistros: action.totalRegistros
      }
    case 'MANUAL_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        nome: action.nome,
        descricao: action.descricao,
        categoria: action.categoria,
        link: action.link,
        tiposUsuariosManuais: action.tiposUsuariosManuais,
        excluido: action.excluido
      }
    case 'MANUAL_CLEAR':
      return initialState
    case 'MANUAL_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'MANUAL_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'MANUAL_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

const initialState = {
  loading: false,
  foto: [],
  open: false,
  id: '',
  nome: '',
  excluido: false,
}

export function tipoFoto(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_TIPO_FOTO':
      return {
        ...state,
        tipoFoto: action.tipoFoto
      }    
    default:
      return state
  }
}

import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { ordemServicoAction } from '../../../ClientApp/src/_actions'

export const ordemServicoOrcamentoAction = {
  getOrdemServicoOrcamento,
  getOrdemServicoOrcamentoByEmpresa,
  getOrdemServicoOrcamentoSelect,
  getOrdemServicoOrcamentoById,
  onChangeProps,
  editOrdemServicoOrcamentoInfo,
  editOrdemServicoOrcamentoAprova,
  editOrdemServicoOrcamentoDeleta,
  editOrdemServicoOrcamentoRecusa,
  editOrdemServicoOrcamentoRevert,
  createOrdemServicoOrcamento,
  createOrdemServicoOrcamentoDeclinar,
  deleteOrdemServicoOrcamentoById,
  clear,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getOrdemServicoOrcamento(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicoOrcamentosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrdemServicoOrcamentoByEmpresa(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos/empresa' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrdemServicoOrcamentosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrdemServicoOrcamentoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'ordensServicoOrcamentos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeOrdemServicoOrcamentosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function editOrdemServicoOrcamentoRevert(id, idOrdemServico, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos/reverterOrcamento/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        dispatch(ordemServicoAction.getOrdemServicoById(idOrdemServico))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
      })
  }
}
function createOrdemServicoOrcamento(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServicoOrcamentos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/ordensServicoOrcamentos')
        } else {
          // toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORDEMSERVICO_ORCAMENTO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORDEMSERVICO_ORCAMENTO_FAILURE', error }
  }
}

function createOrdemServicoOrcamentoDeclinar(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServicoOrcamentos/declinar'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Declínio realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/ordensServicoOrcamentos')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar ordem de serviço! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORDEMSERVICO_ORCAMENTO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORDEMSERVICO_ORCAMENTO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'ORDEMSERVICO_ORCAMENTO_CLEAR'
  }
}

function editOrdemServicoOrcamentoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'ordensServicoOrcamentos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_ORCAMENTO_FAILURE', error }
  }
}

function editOrdemServicoOrcamentoAprova(id, idObra, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos/aprova/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(ordemServicoAction.getOrdemServicoById(idObra))
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_ORCAMENTO_FAILURE', error }
  }
}

function editOrdemServicoOrcamentoDeleta(id, motivo, idOrgao) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos/excluir/' + id
    crudService
      .put(apiEndpoint, { motivoExclusao: motivo ? motivo : null })
      .then((response) => {
        dispatch(getOrdemServicoOrcamento({ Page: 0, IdOrgao: idOrgao }))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        dispatch(getOrdemServicoOrcamento({ Page: 0, IdOrgao: idOrgao }))
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORDEMSERVICO_ORCAMENTO_FAILURE', error }
  }
}

function editOrdemServicoOrcamentoRecusa(id, idObra) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(ordemServicoAction.getOrdemServicoById(idObra))
          dispatch(notIsLoading())
        } else {
          //  toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar ordem de serviço! 😥')
      })
  }
}

function request(payload) {
  return { type: 'EDIT_ORDEMSERVICO_ORCAMENTO_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_ORDEMSERVICO_ORCAMENTO_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_ORDEMSERVICO_ORCAMENTO_FAILURE', error }
}


function getOrdemServicoOrcamentoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'ordensServicoOrcamentos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editOrdemServicoOrcamentosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteOrdemServicoOrcamentoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'ordensServicoOrcamentos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteOrdemServicoOrcamentosDetails())
      dispatch(notIsLoading())
      history.push('/ordensServicoOrcamentos')
      dispatch(getOrdemServicoOrcamento(filterModel))
    })
  }
}
export function changeOrdemServicoOrcamentosList(ordemServicoOrcamento, totalRows) {
  return {
    type: 'FETCHED_ALL_ORDEMSERVICO_ORCAMENTO',
    ordemServicoOrcamento: ordemServicoOrcamento,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'ORDEMSERVICO_ORCAMENTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editOrdemServicoOrcamentosDetails(ordemServicoOrcamento) {
  return {
    type: 'ORDEMSERVICO_ORCAMENTO_DETAIL',
    id: ordemServicoOrcamento.id,
    data: ordemServicoOrcamento.data,
    prazo: ordemServicoOrcamento.data,
    valor: ordemServicoOrcamento.valor,
    statusOrcamento: ordemServicoOrcamento.statusOrcamento,
    idOrgao: ordemServicoOrcamento.idOrgao,
    idOrdemServico: ordemServicoOrcamento.idOrdemServico,
    idEmpresa: ordemServicoOrcamento.idEmpresa,
    excluido: ordemServicoOrcamento.excluido,
    empresa: ordemServicoOrcamento.empresa,
    observacoes: ordemServicoOrcamento.observacoes,
    motivoExclusao: ordemServicoOrcamento.motivoExclusao,
    possuiGarantia: ordemServicoOrcamento.possuiGarantia,
    ordensServicosOrcamentosAnexos: ordemServicoOrcamento.ordensServicosOrcamentosAnexos,
    observacoesGarantia: ordemServicoOrcamento.observacoesGarantia,
    caminhoAnexo: ordemServicoOrcamento.caminhoAnexo,
    declinio: ordemServicoOrcamento.declinio
  }
}
export function createUserInfo() {
  return {
    type: 'ORDEMSERVICO_ORCAMENTO_CREATED_SUCCESSFULLY'
  }
}
export function deleteOrdemServicoOrcamentosDetails() {
  return {
    type: 'DELETED_ORDEMSERVICO_ORCAMENTO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'ORDEMSERVICO_ORCAMENTO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ORDEMSERVICO_ORCAMENTO_NOTISLOADING'
  }
}

const initialState = {
    totalRegistros: 0,
    isLoading: false,
    empresaAnexo: [],
    open: false,
    id: 0,
    idTipoAnexo: 0,
    caminhoAnexo: '',
    dataValidade: '',
    idEmpresa: 0,
    empresa: null,
    excluido: false
}

export function empresaAnexo(state = initialState, action) {
    switch (action.type) {
        case 'CREATE_EMPRESA_ANEXO_REQUEST':
            return {
                ...state,
                isLoading: true
            }
        case 'CREATE_EMPRESA_ANEXO_SUCCESS':
            return {
                ...state,
                id: action.empresaAnexo.id,
                isLoading: false,
                idTipoAnexo: action.empresaAnexo.idTipoAnexo,
                caminhoAnexo: action.empresaAnexo.caminhoAnexo,
                dataValidade: action.empresaAnexo.dataValidade,
                idEmpresa: action.empresaAnexo.idEmpresa,
                empresa: action.empresaAnexo.empresa,
                excluido: action.empresaAnexo.excluido,
                empresaAnexo: action.empresaAnexo.empresaAnexo
            }
        case 'CREATE_EMPRESA_ANEXO_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case 'EDIT_EMPRESA_ANEXO_REQUEST':
            return {
                ...state,
                isLoading: true
            }
        case 'EDIT_EMPRESA_ANEXO_SUCCESS':
            return {
                ...state,
                id: action.empresaAnexo.id,
                isLoading: false,
                idTipoAnexo: action.empresaAnexo.idTipoAnexo,
                caminhoAnexo: action.empresaAnexo.caminhoAnexo,
                dataValidade: action.empresaAnexo.dataValidade,
                idEmpresa: action.empresaAnexo.idEmpresa,
                empresa: action.empresaAnexo.empresa,
                excluido: action.empresaAnexo.excluido,
                empresaAnexo: action.empresaAnexo.empresaAnexo
            }
        case 'EDIT_EMPRESA_ANEXO_FAILURE':
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        case 'FETCHED_ALL_EMPRESA_ANEXO':
            return {
                ...state,
                empresaAnexo: action.empresaAnexo,
                totalRegistros: action.totalRegistros
            }
        case 'EMPRESA_ANEXO_DETAIL':
            return {
                ...state,
                id: action.id,
                isLoading: false,
                idTipoAnexo: action.idTipoAnexo,
                caminhoAnexo: action.caminhoAnexo,
                dataValidade: action.dataValidade,
                idEmpresa: action.idEmpresa,
                excluido: action.excluido,
                empresa: action.empresa,
                empresaAnexo: action.empresaAnexo
            }
        case 'EMPRESA_ANEXO_CLEAR':
            return initialState
        case 'EMPRESA_ANEXO_CLEAR_DETAIL':
            return {
                ...state,
                isLoading: false,
                id: 0,
                idEmpresa: 0,
                caminhoAnexo: '',
                dataValidade: '',
                idTipoAnexo: 0,
                empresa: null
            }
        case 'EMPRESA_ANEXO_ADD_SUCCESS':
            return {
                ...state,
                empresaAnexo: [...state.empresaAnexo, action.data.empresaAnexo]
            }
        case 'EMPRESA_ANEXO_HANDLE_ON_CHANGE':
            return {
                ...state,
                [action.props]: action.value
            }
        case 'EMPRESA_ANEXO_ISLOADING':
            return {
                ...state,
                isLoading: true
            }
        case 'EMPRESA_ANEXO_NOTISLOADING':
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}

import axios from 'axios'
import { errorHandler } from './errorHandler'
import config from '../config/config'
import Resizer from "react-image-file-resizer";

export async function uploadImage(payload) {
  const data = new FormData()
  var compress_image = await resizeFile(payload.file)
  data.append('file', compress_image, payload.file.name)

  return axios
    .post(config.baseUrl + 'uploads/S3', data, getOptions())
    .then((response) => {
      return response.data.url
    })
    .catch((error) => {
      console.log('error', error)
      return errorHandler(error)
    })
}


const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      97,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

//export function getUrl(key) {
//  if (key != null && key.includes('/')) {
//    key = key.substr(key.lastIndexOf('/') + 1, key.length - key.lastIndexOf('/'));
//    console.log('key', key)
//  }
//  return axios
//    .get(config.baseUrl + 'uploads/S3/url/' + key, getOptions())
//    .then((response) => {
//      console.log('response', response)
//      return response.data
//    })
//    .catch((error) => {
//      console.log('error', error)
//      return errorHandler(error)
//    })
//}

function getOptions() {
  let options = {}
  if (localStorage.getItem('token')) {
    options.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }
  return options
}

import React, { useEffect } from 'react';
import clsx from 'clsx'
import { Card, Table, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import CircleIcon from '@mui/icons-material/Circle';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}));

const CardTableFinanceiro = (props) => {
  const { className, valorEmAberto, title, valorPago, valorTotal, textValorTotal, ...rest } = props
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ backgroundColor: '#F7F7F7' }} colSpan={2}>{title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                <CircleIcon sx={{ color: '#cf0d0d', fontSize: 11, marginRight: 1 }} />
               Valor em aberto
              </div>
            </TableCell>
            <TableCell align="right">{valorEmAberto}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
                <CircleIcon sx={{ color: '#0d6e0d', fontSize: 11, marginRight: 1 }} />
                Valor pago
              </div>
            </TableCell>
            <TableCell align="right">{valorPago}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
              <CircleIcon sx={{ color: '#000', fontSize: 11, marginRight: 1 }} />
              <strong>{textValorTotal}</strong>
            </div></TableCell>
            <TableCell align="right">
              <strong>
                {valorTotal}
              </strong>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Card >
  );
}

CardTableFinanceiro.propTypes = {
  title: PropTypes.string,
  valorEmAberto: PropTypes.string,
  valorPago: PropTypes.string,
  valorTotal: PropTypes.string,
  textValorTotal: PropTypes.string
}

export default CardTableFinanceiro

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { uploadFile } from '_services'
import {
    Backdrop,
    Button,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    FormLabel,
    Grid,
    Input,
    Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
    ordemServicoAction
} from '../../_actions'
import {
    ValidatorForm,
    TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
})

export const OrdemServicoModalConcluirServico = ({ modal, toggle, props, idOrdemServico }) => {

    const formRef = React.createRef(null);

    const dispatch = useDispatch()
    const { classes, className, ...rest } = props
    const { match: { params } } = props;
    const [servicoRealizado, setServicoRealizado] = useState('')
    const [open, setOpen] = useState(false)
    const [filesUploaded, setFilesUploaded] = useState([]);

    const handleUploadClickFiles = (event) => {
        const files = Array.from(event.target.files); // Transformando em array para iteração

        setOpen(true);

        // Array para armazenar as promessas de upload
        const uploadPromises = files.map((file) =>
            uploadFile(file)
                .then((response) => {
                    return { caminhoArquivo: response.toString() };
                })
                .catch((err) => {
                    console.log(err);
                    return null;
                })
        );

        // Esperar que todos os uploads sejam concluídos
        Promise.all(uploadPromises)
            .then((uploadedFiles) => {
                // Filtrar arquivos que tiveram sucesso no upload (não são null)
                const successfulUploads = uploadedFiles.filter((file) => file !== null);
                setFilesUploaded([...filesUploaded, ...successfulUploads]);
                setOpen(false);
            })
            .catch((err) => {
                console.log(err);
                setOpen(false);
            });
    };

    const handleClose = () => {
        toggle();
    }

    const handleSubmit = () => {
        let payload = {
            //o campo, nas ocorrências se chama "descricao"
            descricao: servicoRealizado,
            ordensServicosOcorrenciasAnexos: filesUploaded
        }

        dispatch(ordemServicoAction.editOrdemServicoConclui(idOrdemServico, payload));
        toggle();
    };

    function InsertText() {
        return (
            <Typography variant="h6">
                <b>{'Concluir Ordem de Serviço'}</b>
            </Typography>
        )
    }

    function SegHeader() {
        return <InsertText />
    }

    return (
        <Dialog
            open={modal}
            fullWidth
            style={{ padding: 0, margin: 0 }}
            maxWidth='md'
            onClose={toggle}
        >
            <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
                <Button
                    variant="contained"
                    style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
                    onClick={() => handleClose()}>
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent style={{ padding: 0, margin: 0 }}>
                <Card style={{ marginTop: 0 }}>
                    <ValidatorForm
                        style={{ marginTop: 0, paddingTop: 0 }}
                        ref={formRef}
                        id="formUsuario"
                        onSubmit={(event) => handleSubmit(event)}
                    >
                        <CardHeader
                            style={{ marginTop: 0, paddingTop: 0 }}
                            subheader="Descrever serviço realizado"
                            title={<SegHeader />}
                        />
                        <Divider />
                        <CardContent>
                            <Grid container>
                                <Grid item lg={12} md={12} xl={12} xs={12}>
                                    <TextValidator
                                        className={classes.textField}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        id="servicoRealizado"
                                        label="Descreva o serviço que foi realizado"
                                        multiline
                                        rows={3}
                                        margin="normal"
                                        onInput={stringUtils.toInputUppercase}
                                        onChange={(e) => setServicoRealizado(e.target.value)}
                                        value={servicoRealizado}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item lg={12} md={12} xl={12} xs={12}>
                                    <Backdrop className={styles.backdrop} open={open}>
                                        <Grid
                                            container
                                            spacing={3}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            alignContent="center"
                                            wrap="nowrap"
                                        >
                                            <Grid item>
                                                <CircularProgress color="primary" />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h4" color="textPrimary">
                                                    Enviando arquivo (s)...
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Backdrop>
                                    <FormLabel style={{ fontSize: 12 }} component="legend">Arquivo (s)</FormLabel>
                                    <Input
                                        id="caminhoArquivo"
                                        className={classes.textField}
                                        label="Arquivo (s)"
                                        onChange={e => handleUploadClickFiles(e)}
                                        type="file"
                                        variant="outlined"
                                        inputProps={{ multiple: true }}
                                        placeholder="Enviar arquivo(s)"
                                        formControl
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions>
                            <Grid container style={{
                                display: 'flex',
                                alignItems: 'end',
                                flexDirection: 'row',
                                textAlign: 'end',
                                justifyContent: 'end'
                            }}>
                                <Button
                                    variant="outlined"
                                    style={{ color: '#000', borderColor: '#000' }}
                                    onClick={toggle}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ backgroundColor: '#000' }}
                                    disabled={props.ordemServico.isLoading}
                                    startIcon={<SaveIcon />}
                                    endIcon={props.ordemServico.isLoading && <CircularProgress size={24} />}
                                >
                                    {props.ordemServico.isLoading ? 'Concluindo ordem de serviço...' : 'Concluir ordem de serviço'}
                                </Button>
                            </Grid>
                        </CardActions>
                    </ValidatorForm>
                </Card>
            </DialogContent>
        </Dialog >
    )
}
